<div
  class="row"
  style="margin-top: 40px"
  [appMyStyles]="component"
  (click)="navigateToShow(imageTitleDescriptionData?.id)"
>
  <div
    class="col-md-6 col-sm-12 col-xs-12 padd"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    *ngIf="component?.image_position == 'left' && component?.main_id != 15767 && component?.main_id != 19671 && component?.main_id != 22483 && component?.main_id !=  8288 && component?.main_id !=  8289"
    style="text-align: center;"
  >
    <img *ngIf="lang == 'en_container' && Data?.length>0" class="img-style2" alt=""  src="{{ Data[0]?.image }}" />
    <img *ngIf="lang != 'en_container'" class="img-style2" src="assets/images/Who we are.png" />

  </div>

  <div
  *ngIf="component?.main_id != 15767 && component?.main_id != 19671 && component?.main_id != 22483 && component?.main_id !=  8288 && component?.main_id !=  8289"
  class="col-md-6 col-sm-12 col-xs-12 "
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
>
  <h6
    class="title2"
    [ngStyle]="{ color:component?.title_font_color, 'font-size': component?.title_font_size }"
  >
    {{ component?.component_title }}
  </h6>
  <div class="lines">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>

  <h6 [ngStyle]="{ color: component?.title_font_size, 'font-size': component?.component_sub_title }">
    {{ component?.component_sub_title }}
  </h6>

  <h1
  *ngIf="Data?.length>0"
    class="sub-title"
    [innerHtml]="Data[3]?.title_detailed"
  ></h1> 

  <p *ngIf="Data?.length>0" class="paragraph" [innerHTML]="Data[0]?.content || ''"></p>

  <!-- <p
    class="paragraph "
    
    [innerHtml]="Data[0].description_detailed"
  ></p> -->
  <!-- <p
    class="paragraph border-style-right"
    *ngIf="currentLang == '2' "
    [ngStyle]="{ 'border-right-color': websiteSecColor }"
    [innerHtml]="Data[0].description_detailed"
  ></p> -->
 </div>



  <div
    class="col-md-6 col-sm-12 col-xs-12 padd"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    *ngIf="component?.image_position == 'left' && component?.main_id == 8288"
  >
    <img class="img-style" src="{{ imageTitleDescriptionData?.image }}" />
  </div>


  <div   
        class="col-md-6 col-sm-12 col-xs-12 "
        [class]="component?.animations?.length > 1 ? component?.animations[1] : ''">
        <h1
        class="title"
        [ngStyle]="{ color:component?.title_font_color, 'font-size': component?.title_font_size }"
        [innerHtml]="imageTitleDescriptionData?.title_detailed"
      ></h1>

        <h6
        style="font-size: 40px;"
        [ngStyle]="{ color:component?.title_font_color}"
      >
        {{ component?.component_title }}
      </h6>
  
      <h6 
         style="font-size: 30px;"
         [ngStyle]="{ color: component?.title_font_size}">
        {{ component?.component_sub_title }}
      </h6>
  
     
  
      <p class="paragraph" [innerHTML]="imageTitleDescriptionData?.content"></p>
  
      <p
        class="paragraph border-style-left"
        *ngIf="currentLang == '1' "
        [ngStyle]="{ 'border-left-color': websiteSecColor }"
        [innerHtml]="imageTitleDescriptionData?.description_detailed || ''"
      ></p>
      <p
        class="paragraph border-style-right"
        *ngIf="currentLang == '2' "
        [ngStyle]="{ 'border-right-color': websiteSecColor }"
        [innerHtml]="imageTitleDescriptionData?.description_detailed || '' "
      ></p>

  </div>

  <div   
  class="col-md-6 col-sm-12 col-xs-12 padd"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  *ngIf="component?.image_position == 'right' && component?.main_id == 8288 "
  >
  <img class="img-style"  src="{{ imageTitleDescriptionData?.image }}" />
  </div>

  
  <div
    class="col-md-6 col-sm-12 col-xs-12 padd"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    *ngIf="component?.image_position == 'left' && component?.main_id == 8289"
  >
    <img class="img-style" src="{{ imageTitleDescriptionData?.image }}" />
  </div>


  <div
        class="col-md-6 col-sm-12 col-xs-12 "
        [class]="component?.animations?.length > 1 ? component?.animations[1] : ''">
        <h1
        class="title"
        [ngStyle]="{ color:component?.title_font_color, 'font-size': component?.title_font_size }"
        [innerHtml]="imageTitleDescriptionData?.title_detailed"
      ></h1>

        <h6
        style="font-size: 40px;"
        [ngStyle]="{ color: component?.title_font_color}"
      >
        {{ component?.component_title }}
      </h6>
  
      <h6 
         style="font-size: 30px;"
         [ngStyle]="{ color: component?.title_font_size}">
        {{ component?.component_sub_title }}
      </h6>
  
     
  
      <p class="paragraph" [innerHTML]="imageTitleDescriptionData?.content"></p>
  
      <p
        class="paragraph border-style-left"
        *ngIf="currentLang == '1' "
        [ngStyle]="{ 'border-left-color': websiteSecColor }"
        [innerHtml]="imageTitleDescriptionData?.description_detailed"
      ></p>
      <p
        class="paragraph border-style-right"
        *ngIf="currentLang == '2' "
        [ngStyle]="{ 'border-right-color': websiteSecColor }"
        [innerHtml]="imageTitleDescriptionData?.description_detailed"
      ></p>

  </div>

  <div   
  class="col-md-6 col-sm-12 col-xs-12 padd"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  *ngIf="component?.image_position == 'right' && component?.main_id == 8289 "
  >
  <img class="img-style"  src="{{ imageTitleDescriptionData?.image }}" />
  </div>


  <div
    class="col-md-6 col-sm-12 col-xs-12 padd"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    *ngIf="component?.image_position == 'left' && component?.main_id != 22481 && component?.main_id != 19674  && component?.main_id !=  8278 && component?.main_id != 8285 && component?.main_id !=  8288 && component?.main_id !=  8289"
  >
    <img class="img-style" src="{{ imageTitleDescriptionData?.image }}" />
  </div>


  <div 
        class="col-md-6 col-sm-12 col-xs-12 padd"
        [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
        *ngIf="component?.main_id != 22481 && component?.main_id != 19674 && component?.main_id !=  8278 && component?.main_id != 8285 && component?.main_id !=  8288 && component?.main_id !=  8289"
  >
        <h1
        class="title"
        [ngStyle]="{ color:component?.title_font_color, 'font-size': component?.title_font_size }"
        [innerHtml]="imageTitleDescriptionData?.title_detailed"
      ></h1>

        <h6
        style="font-size: 40px;"
        [ngStyle]="{ color:component?.title_font_color}"
      >
        {{ component?.component_title }}
      </h6>
  
      <h6 
         style="font-size: 30px;"
         [ngStyle]="{ color: component?.title_font_size}">
        {{ component?.component_sub_title }}
      </h6>
  
     
  
      <p class="paragraph" [innerHTML]="imageTitleDescriptionData?.content"></p>
  
      <p
        class="paragraph border-style-left"
        [innerHtml]="imageTitleDescriptionData?.description_detailed || ''"
      ></p>
      

  </div>

  <div   
  class="col-md-6 col-sm-12 col-xs-12 padd"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  *ngIf="component?.image_position == 'right'  && component?.main_id != 22481 && component?.main_id != 19674 && component?.main_id !=  8278 && component?.main_id != 8285 && component?.main_id !=  8288 && component?.main_id !=  8289"
  >
  <img class="img-style"  src="{{ imageTitleDescriptionData?.image }}" />
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleDescriptionData?.length == 0">
      <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>
