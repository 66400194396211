<div [appMyStyles]="component">

  <app-header-title-desc [article]="component"></app-header-title-desc>

    <div class="row">

        <div  class="col-md-12 col-sm-12 col-xs-12"     
             *ngFor="let item of faqData; index as g" id="g_{{ g }}"
        >
      
            <div class="separator-short"></div>
            <div
              class="accordion"
            >
              <div class="accordion-item" (click)="showAnswer(g, g)">
                <div class="accordion-order"></div>
                <span class="target-fix" id="accordion-2-1"></span>
                <i class="fa fa-angle-down icon" aria-hidden="true"></i>
                <a id="open-accordion-2-1" title="open" [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
                > {{item?.title}}
                </a>
                <span  class="display--none answer" id="ans_q{{g}}_g{{g}}">
                  <p  [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
                  >
                  {{item?.description}}
                  </p>
                 
              </span>
              </div>
          
            </div>
        </div>

    </div>





</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="faqData?.length == 0">
  <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>