<div [appMyStyles]="component">

  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div
    class="d-flex align-items-stretch"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    [ngClass]="{ 'flex-row-reverse': !component?.image_position }"
    *ngIf="component?.main_id != 10055"
  >
    <div
      class="col-md-6 col-sm-12 col-xs-12 mx-3 background"
      [ngStyle]="{
        'background-image': 'url(' + component.component_background_image + ')'
      }"
    >
      <img
      *ngIf="imageTitleData?.length>0"
        [ngStyle]="{
          'rounded-sm': isCurve
        }"
        class="main-img"
        src="{{ imageTitleData[0]?.image }}"
      />
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12 padd" >

      <div
        class="row"
        *ngFor="let item of imageTitleData | slice : 1 : component.items_number"
      >
        <div class="col-md-2 col-sm-2 col-xs-2">
          <img class="icon_benfit rounded-lg  bg-white rounded shadow" src="{{ item.image }}" />
        </div>

        <div class="col-md-10 col-sm-10 col-xs-10 align-items-center">
          <p>{{item.title}}</p>
          <span class="dis" >{{item.description}}</span>
        </div>

      </div>

    </div>
  </div>

  <div
  class="d-flex"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  [ngClass]="{ 'flex-row-reverse': !component?.image_position }"
  *ngIf="component?.main_id == 10055"
>
  <div
    class="col-md-6 col-sm-12 col-xs-12 mx-3 "
    style="margin-bottom: 25px;height: 500px;"

  >
    <img
    *ngIf="imageTitleData?.length>0"
      [ngStyle]="{
        'rounded-sm': isCurve
      }"
      class="main-img2"
      src="{{ imageTitleData[6]?.image }}"
    />
  </div>

  <div class="col-md-6 col-sm-12 col-xs-12 " >

    <div
      class="row"
      style="margin-bottom: 25px;"
      *ngFor="let item of imageTitleData | slice : 0 : 5"
    >
      <div class="col-md-2 col-sm-2 col-xs-2">
        <img class="icon_benfit rounded-lg  bg-white rounded shadow" src="{{ item.image }}" />
      </div>

      <div class="col-md-10 col-sm-10 col-xs-10 align-items-center">
        <p>{{item.title}}</p>
        <span class="dis" >{{item.description}}</span>
      </div>

    </div>

  </div>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleData?.length == 0">
    <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>

</div>
