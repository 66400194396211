<div class="container-fluid" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  class="row parralax d-flex"
  [ngStyle]="{
    'background-image': component?.bg_source != 'component_Bg' && component?.component_background_color==null  ?  'url(' + sliderData[0]?.image + ')' : 'url(' + component?.component_background_image+ ')'
  }"
  [ngClass]="
    mainImage== null ? 'no-image' : ''"
  [appMyStyles]="component" [appSliderStyle]="component" 
>
  <div class="general title-btn" 
  
  [ngClass]="[
    mainImage!= null ? 'col-lg-8 col-md-6 col-sm-8' : 'col-md-12 col-sm-12 col-xs-12',
    component?.content_position_vertical=='bottom' ? 'justify-content-end' : component?.content_position_vertical=='top' ?  'justify-content-start' : 'justify-content-center'
]">
    <h2
     
    [ngStyle]="{'font-size':component?.title_size, 
    'color':component?.title_color,
    'font-weight':component?.data_title_weight,
        'z-index':1
      }"
      [ngClass]="[component?.component_title_position=='center'?'text-center':'text-start w-100',
        component?.content_position_vertical=='bottom'?'w-100':'w-75'
       ]">
      {{ sliderData[0]?.title }}
    </h2>

    <h6
     
      class="component-desc"
      [ngStyle]="{
        color: component.description_color,
        'font-size': component.description_size,
        'font-weight': component?.data_desc_weight
      }"
       [ngClass]="[component?.Style_content_position=='0'?'text-center':'text-start',
         component?.content_position_vertical=='bottom'?'w-100':'w-50'
       ]" 
    >
    {{ sliderData[0]?.description }}

    </h6>
    <div *ngIf=" component?.component_buttons?.length>0">
      <button 
        class="volunteer-btn"
        (click)="navigateToShow(sliderData[0]?.id)"
        [ngStyle]="{
          'background-color':
            component?.component_buttons[0]?.button_background_color,
          'color': component?.component_buttons[0]?.button_color,
          'font-size': component?.component_buttons[0]?.button?.font_size,
          'font-weight': component?.component_buttons[0]?.button?.font_weight
        }"
        [ngClass]="[component?.Style_content_position=='0'?'text-center':'text-start']"
      >
        <ng-container *ngIf="lang == 'ar_container'"
          >{{ component?.component_buttons[0]?.button_text }}
        </ng-container>
        <ng-container *ngIf="lang == 'en_container'"
          >{{ component?.component_buttons[0]?.button_text }}
        </ng-container>
      </button>
   
    </div>
    <div class="overlay" *ngIf="component?.content_position_vertical=='bottom'"></div>
  
  </div>
  
  <ng-container *ngIf="mainImage!= null && component?.bg_source == 'component_Bg'">
    <div class="col-lg-4 col-md-6 col-sm-4 px-0">
      <img [src]="sliderData[0]?.image" 
      [ngClass]="component.Style_image_shape=='1'?'slider-image-side':'slider-image-circle'"
       [ngStyle]="{'border-radius':lang == 'ar_container' && component.Style_image_shape=='1'?'0px 16px 16px 0px':''}">
    </div>
  </ng-container>
  <ng-container *ngIf="sliderData?.length>1 && component?.slider_description == 1">
      <div class="col-lg-4 col-md-4 col-sm-6 py-lg-2 p-md-0 pb-2" *ngFor="let item of sliderData | slice:1:4">
        <img class="icon-details" [src]="item.image">
        <span class="px-2"   [ngStyle]="{
          color: component.sub_color,
          'font-size': component.Sub_size,
          'font-weight': component?.data_desc_weight
        }"> {{item.title}}</span>
      </div>
 
  </ng-container>
 
</div>
<div class="alert alert-warning text-center" role="alert" *ngIf="sliderData?.length == 0 " >
  <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 

</div>
