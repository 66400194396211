<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div class="row" [appMyStyles]="component">
  <div
    class="col-md-3 col-sm-12 col-xs-12"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    style="margin-bottom: 15px"
    *ngFor="let item of threeCardsData"
  >
    <div class="home-article hover-style" (click)="navigateToShow(item?.id)">
      <a
        *ngIf="item?.type == 'image'"
        class="cover-img"
        [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
      >

      <h4 class="card-title" [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      >
         {{ item?.title }}
       </h4>
        <div class="home-article-desc text-left">
        
         

        
          <p
            class="card-paragraph" [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
         
          >
            {{ item?.description }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
    <div class="row d-flex justify-content-center mb-5">
      
      <button
      *ngIf="threeCardsData?.length > 0"
      [ngStyle]="{
        color: websiteSecColor
      }"
      class="btn see-more"
    >
    <i class="fas fa-chevron-down" aria-hidden="true"></i>
     
    {{'see more' | translate}}

    </button>
    </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="threeCardsData?.length == 0">
      <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  

