<div class="container" style="margin-top: 40px">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    [appMyStyles]="article" 
  >
    <div [appGridStyle]="article"
      class="col-md-4 col-sm-12 col-xs-12 py-4"
      *ngFor="let item of Cards6_Slider_Data"
    >
      <div class="card" (click)="navigateToShow(item.id)">
        <img  [src]="item?.image"  class="card-img-top">
        <div
          *ngIf="item.type == 'image'"
         
         
        >
     
          <div class="card-body py-0">
            <div class="event-desc ">
             
              <h4
                class="card-title"
                [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
              >
                {{ item.title }}
              </h4>
              <p 
    [ngStyle]="{'font-size':article?.description_size, 'color':article?.description_color,'font-weight':article?.data_desc_weight}"
    >
                {{ item.description }}
              </p>

              <a class="more"  *ngIf="article?.article_buttons?.length>0">
                <!-- {{'view more'|translate}} -->
                <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                <span
                  style="color: gray !important"
                  class="pull-right mar-left-30"
                >
                  <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                  {{ "share" | translate }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <p-carousel [value]="slides"  [autoplayInterval]="3000"  [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptions">
            <ng-template let-slide pTemplate="item">
                <div class="swiper-slide">




                </div>
            </ng-template>
        </p-carousel> -->
  </div>
</div>
<div class="alert alert-warning text-center" role="alert" *ngIf="Cards6_Slider_Data?.length == 0" >
  <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>
