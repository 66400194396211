<app-loader></app-loader>
<div
  [ngStyle]="{ 'font-family': website_font_type }"
  [hidden]="loadingService.isLoading | async"
>
<!-- <div class="edit-header" (click)="showDialog('header')">
  <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
</div> -->

<!-- 11 , 12, 17, 18 ,19 ,29 ,37 -->
  <app-header *ngIf="Header == '11' && showHeader"></app-header>  
  <app-header2 *ngIf="Header == '12' && showHeader"></app-header2>
  <app-menu-transparent *ngIf="Header == '17' && showHeader"></app-menu-transparent>
  <!-- <app-header4 *ngIf="Header == '17' && showHeader"></app-header4> -->
  <app-header5 *ngIf="Header == '18' && showHeader"></app-header5>
  <app-header3 *ngIf="Header == '19' && showHeader"></app-header3>
  <app-academy-menu *ngIf="Header == '29' && showHeader"></app-academy-menu>
  <!-- <app-header-side *ngIf="Header == '28' && showHeader"></app-header-side> -->
  <!-- <app-header-background *ngIf="Header == '29' && showHeader"></app-header-background> -->
  <app-header-button *ngIf="Header == '30' && showHeader"></app-header-button>
  <app-header-with-two-button *ngIf="Header == '37' && showHeader"></app-header-with-two-button>

  <app-popup></app-popup>
  <div
   
    [ngStyle]="{ 'background-color': website_background_color }"
  >
    <router-outlet></router-outlet>
  </div>

  <!-- <app-loader></app-loader>
      <router-outlet></router-outlet> -->
      <!-- <div class="edit-header" (click)="showDialog('footer')">
        <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
      </div>  -->
      <!-- 7 , 14 ,9 , 20 , 16 -->

      <app-etaam-footer *ngIf="Footer == '7' && showHeader"></app-etaam-footer>
      <app-footer2 *ngIf="Footer == '9' && showHeader"></app-footer2>
      <app-footer4 *ngIf="Footer == '20' && showHeader"></app-footer4>
      <app-footer5 *ngIf="Footer == '14' && showHeader"></app-footer5>
      
      <app-links-footer *ngIf="Footer != '7' && Footer != '9' && Footer != '14' && Footer != '20'"></app-links-footer>
     
    
    
</div>
