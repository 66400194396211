<div
  class="d-flex flex-row parralax justify-content-center align-items-center"
  [ngStyle]="{
    'background-image': 'url(' + Adertising_Data[0]?.image + ')'
  }"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
  <div class="d-flex flex-column justify-content-start col-10">
    <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="d-flex flex-column">
      <h5
     
      
      >{{Adertising_Data[0]?.title}}</h5>
      <p
    
      >{{Adertising_Data[0]?.description}}</p>
    </div>
    <div class="d-flex row my-4 ml-1">
      <input class="col-7 form-control" type="search" placeholder="search" />
    </div>
  </div>
</div>
