import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

interface StyleConfig {
  alignItems:string;
  flexDirection: string;
  padding:string;
  textAlign:string;

  [key: string]: string;
}
@Directive({
  selector: '[appSliderStyle]'
})

export class SliderStyleDirective {
  private _styleConfig: StyleConfig;

  @Input("appSliderStyle")
  set appGridStyle(grid: any) {
    const conditions = {
      alignItems: grid?.Style_content_position === 0 && grid?.component_title_position === 'center' ? "center" : "", 
      justifyContent: grid?.Style_content_position === 0 && grid?.component_title_position === 'center' ? "center" : "", 
      padding:grid?.Style_image_shape == 0?'100px 50px':'',
      textAlign:grid?.component_title_position === 'center' ? "center" : "",
    };

    const {
      justifyContent: flexDirection,
      alignItems,
      textAlign,
      padding
      
    } = conditions;
    // Update the private property and apply styles
    this._styleConfig = {
      flexDirection,
      padding,
      textAlign,
      alignItems
    
    };
    this.applyStyles();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private applyStyles() {
    // Reset styles before applying new styles
    this.resetStyles();
    // Iterate over the keys in the styleConfig object
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        const value = this._styleConfig[prop];
        // Apply styles based on the property and value
        this.renderer.setStyle(this.el.nativeElement, prop, value);
      }
    }
  }

  private resetStyles() {
    // Reset all styles before applying new styles
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        this.renderer.removeStyle(this.el.nativeElement, prop);
      }
    }
  }
}
