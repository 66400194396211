import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-icon-bg-title-desc-card3",
  templateUrl: "./icon-bg-title-desc-card3.component.html",
  styleUrls: ["./icon-bg-title-desc-card3.component.scss"],
})
export class IconBgTitleDescCard3Component implements OnInit {
  @Input() article: any;

  Three_Circles_Data;
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Three_Circles_Data = res.content;
        console.log( this.Three_Circles_Data );
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Three_Circles_Data = res.content;
         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
