<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>
    <div [appMyStyles]="article"
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
    <div class="col-md-6 col-sm-12 col-xs-12 d-flex"  *ngIf="TwoTitleDescriptionCardData?.length>0">
        <img class="main-img" [src]="TwoTitleDescriptionCardData[0]?.image " />
        <img class="main-img2" [src]="TwoTitleDescriptionCardData[1]?.image " />
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="TwoTitleDescriptionCardData?.length>0">

  
        <h2   [ngStyle]="{
                       'font-size': article.title_size,
                       color: article.title_color
                     }"
              style="font-weight: bold;"        
        >
          {{ TwoTitleDescriptionCardData[0]?.title }}
        </h2>
        <p  [ngStyle]="{
                        'font-size': article.description_size,
                        color: article.descriptiont_color
            }"
        >
          {{ TwoTitleDescriptionCardData[0]?.description }}
        </p>
  
       
      </div>
  
     
    </div>
    
<!-- <div  class="vr-line" [ngClass]="currentLang == 'ar_container'?'vr-line-ar':'vr-line-en' "><span></span></div> -->
    <div [appMyStyles]="article" 
      class="row padd"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin-top: 40px;"
      *ngIf="TwoTitleDescriptionCardData?.length>0"
    >
       <div class="col-md-6 col-sm-12 col-xs-12 " 
            *ngFor="let item of TwoTitleDescriptionCardData | slice : 0 : 2">
            <p 
               [ngStyle]="{
                          'font-size': article?.title_size,
                          color: article?.title_color
                }" >{{item.title}}</p>
            <p class="padd-right "
                [ngStyle]="{
                        'font-size': article?.description_size,
                         color: article?.description_color
                 }"> {{item.description}} </p>
        </div>

    </div>


    <div class="alert alert-warning text-center" role="alert" *ngIf="TwoTitleDescriptionCardData?.length == 0" >
      <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
    </div>
    