<div
  [ngStyle]="{
    'background-color': websiteMainColor
  }"
  [appMyStyles]="article"
>
  <div
    class="row statistics-title"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-12" style="text-align: center">
      <h3 [ngStyle]="{ color: title_font_color, 'font-size': titleFontSize }">
        {{ ComponentTitle }}
      </h3>
    </div>
  </div>

  <div *ngIf="statisticsData?.length>0"
    class="row statistics-container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-3 col-sm-6 col-xs-12"
      *ngFor="let item of statisticsData | slice : 0 : 4"
      
    >
      <div class="statistics-box" (click)="navigateToShow(item.id)">
        <img src="{{ item.image }}" style="width: 120px; height: 120px" />
        <h1 class="number" [innerHtml]="item.title_detailed"></h1>
        <p class="statistics-text" [innerHtml]="item.description_detailed"></p>
        <p class="statistics-text">{{ item.description }}</p>
      </div>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="statisticsData?.length == 0">
      <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>
