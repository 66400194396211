<div [appMyStyles]="component" class="container mt-5">
  <div class="row" [ngClass]="{ 'flex-row-reverse': component.image_position }">
    <app-header-title-desc [article]="component"></app-header-title-desc>
    <div
      class="d-flex col position-relative justify-content-center"
      [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    >
      <img
      *ngIf="imageTitleData?.length>0"
        [ngClass]="{ 'rounded-lg': isCurve }"
        class="img"
        src="{{ imageTitleData[0]?.image }}"
      />
      <button
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
        class="rounded-2 btn position-absolute join mb-5"
      >
        {{ component.button_text }}
      </button>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleData?.length == 0">
      <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>
