<div [appMyStyles]="article" *ngIf="article.main_id != 20625">
  <div class="row mb-7 mt-7">
    <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>
  
  <div
    class="row"
    style="text-align: center"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngIf="slides?.length>0"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12 partner-container"
      style="align-items: center; padding: 0px 40px"
    >
      <!-- <h4  *ngIf="lang ==  'en_container' " class="component-title-left" [ngStyle]="{'border-left-color':websiteMainColor}">{{ComponentTitle}}</h4> -->
      <!-- <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  [ngStyle]="{'border-right-color':websiteMainColor}" >{{ComponentTitle}}</h4> -->
  
      <p-carousel
        [value]="slides"
        [circular]="true"
        [autoplayInterval]="10000"
        [numVisible]="4"
        [numScroll]="1"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-slide pTemplate="item">
          <div class="swiper-slide" (click)="navigateToShow(slide.id)">
            <img class="logo-img" [src]="slide.image" />
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="slides?.length == 0">
      <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>


<div [appMyStyles]="article" *ngIf="article.main_id ==20625">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    style="text-align: center"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngIf="slides?.length>0"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12 partner-container"
      style="align-items: center; padding: 0px 40px"
    >
  
      <p-carousel
        [value]="slides"
        [circular]="true"
        [numVisible]="6"
        [numScroll]="1"
        [responsiveOptions]="responsiveOptions"
        [autoplayInterval]="50000"

      >
        <ng-template let-slide pTemplate="item">
          <div class="swiper-slide">
            <img class="logo-img2" [src]="slide.image" />
          </div>
        </ng-template>
      </p-carousel>
      
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="slides?.length == 0">
      <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>

