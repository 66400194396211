import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from "ngx-swiper-wrapper";
import { MatSelectModule } from "@angular/material/select";
import { DropdownModule } from "primeng/dropdown";
import { AboutUsDescriptionComponent } from "./components/about-us-description/about-us-description.component";
import { AboutUsHeaderComponent } from "./components/about-us-header/about-us-header.component";
import { AboutUsOverviewComponent } from "./components/about-us-overview/about-us-overview.component";
import { AboutUsTornadoComponent } from "./components/about-us-tornado/about-us-tornado.component";
import { AcademyMenuComponent } from "./components/academy-menu/academy-menu.component";
import { AcademyRegisterComponent } from "./components/academy-register/academy-register.component";
import { AcademyRegisterNoBgComponent } from "./components/academy-register-no-bg/academy-register-no-bg.component";
import { AdvertisingComponent } from "./components/advertising/advertising.component";
import { ArticleCardComponent } from "../articles/components/article-card/article-card.component";
import { AuthenticationModule } from "../authentication/authentication.module";
import { BigImageSideComponent } from "./components/big-image-side/big-image-side.component";
import { BigVideoComponent } from "./components/big-video/big-video.component";
import { ButtonCards2Component } from "./components/button-cards2/button-cards2.component";
import { ButtonCards4Component } from "./components/button-cards4/button-cards4.component";
import { Card3VerticalComponent } from "./components/card3-vertical/card3-vertical.component";
import { Card3WhiteBgComponent } from "./components/card3-white-bg/card3-white-bg.component";
import { CardEventComponent } from "../events/components/card-event/card-event.component";
import { CardLocationComponent } from "./components/card-location/card-location.component";
import { CardOpportunityComponent } from "./card-opportunity/card-opportunity.component";
import { Cards3Component } from "./components/cards3/cards3.component";
import { Cards4Component } from "./components/cards4/cards4.component";
import { Cards4ObjectivesCardComponent } from "./components/cards4-objectives-card/cards4-objectives-card.component";
import { Cards6Component } from "./components/cards6/cards6.component";
import { Cards6SliderComponent } from "./components/cards6-slider/cards6-slider.component";
import { CardsComponent } from "./components/cards/cards.component";
import { CardsListComponent } from "./components/cards-list/cards-list.component";
import { CardsSliderComponent } from "./components/cards-slider/cards-slider.component";
import { CardsTitleListComponent } from "./components/cards-title-list/cards-title-list.component";
import { CarouselModule } from "primeng/carousel";
import { Circles3Component } from "./components/circles3/circles3.component";
import { Circles4Component } from "./components/circles4/circles4.component";
import { CmsComponent1Component } from "./components/cms-component1/cms-component1.component";
import { CmsComponent2Component } from "./components/cms-component2/cms-component2.component";
import { CmsComponent3Component } from "./components/cms-component3/cms-component3.component";
import { CmsComponent4Component } from "./components/cms-component4/cms-component4.component";
import { CmsComponent5Component } from "./components/cms-component5/cms-component5.component";
import { CmsComponent6Component } from "./components/cms-component6/cms-component6.component";
import { CmsComponent7Component } from "./components/cms-component7/cms-component7.component";
import { CmsComponent8Component } from "./components/cms-component8/cms-component8.component";
import { CmsGeneralCardComponent } from "./components/cms-general-card/cms-general-card.component";
import { CmsMostWatchedComponentComponent } from "../cms/components/cms-most-watched-component/cms-most-watched-component.component";
import { CommonModule } from "@angular/common";
import { DateCards3Component } from "./components/date-cards3/date-cards3.component";
import { DateFormatterPipe } from "./pipes/date-formatter.pipe";
import { DocumentaryComponent } from "./components/documentary/documentary.component";
import { EtaamFooterComponent } from "./components/etaam-footer/etaam-footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { EventBigCardComponent } from "./components/event-big-card/event-big-card.component";
import { EventsCards3Component } from "./components/events-cards3/events-cards3.component";
import { FaqHeaderComponent } from "./components/faq-header/faq-header.component";
import { FaqQuestionsComponent } from "./components/faq-questions/faq-questions.component";
import { Footer1Component } from "./components/footer1/footer1.component";
import { Footer2Component } from "./components/footer2/footer2.component";
import { Footer3Component } from "./components/footer3/footer3.component";
import { Footer4Component } from "./components/footer4/footer4.component";
import { FourLogosImageComponent } from "./components/four-logos-image/four-logos-image.component";
import { Gallery3Component } from "./components/gallery3/gallery3.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { GalleryListComponent } from "./components/gallery-list/gallery-list.component";
import { GetintouchComponent } from "./components/getintouch/getintouch.component";
import { GridCards5Component } from "./components/grid-cards5/grid-cards5.component";
import { GridCardsComponent } from "./components/grid-cards/grid-cards.component";
import { Header1Component } from "./components/header1/header1.component";
import { Header2Component } from "./components/header2/header2.component";
import { Header3Component } from "./components/header3/header3.component";
import { Header4Component } from "./components/header4/header4.component";
import { Header5Component } from "./components/header5/header5.component";
import { IconBgTitleDescCard3Component } from "./components/icon-bg-title-desc-card3/icon-bg-title-desc-card3.component";
import { IconTitleDescCard3Component } from "./components/icon-title-desc-card3/icon-title-desc-card3.component";
import { ImagePararaphComponent } from "./components/image-pararaph/image-pararaph.component";
import { ImageSliderComponent } from "./components/image-slider/image-slider.component";
import { ImageTitleDescHorizontzalCardsComponent } from "./components/image-title-desc-horizontzal-cards/image-title-desc-horizontzal-cards.component";
import { ImageTitleDescriptionComponent } from "./components/image-title-description/image-title-description.component";
import { ImageTitleSubtitleDescriptionButtonCardComponent } from "./components/image-title-subtitle-description-button-card/image-title-subtitle-description-button-card.component";
import { ImgTitleDescriptionCardComponent } from "./components/img-title-description-card/img-title-description-card.component";
import { LinksFooterComponent } from "./components/links-footer/links-footer.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoaderService } from "../shared/shared_services/loader.service";
import { LogoSliderComponent } from "./components/logo-slider/logo-slider.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatStepperModule } from "@angular/material/stepper";
import { MediaComponent } from "./components/media/media.component";
import { MenuTransparentComponent } from "./components/menu-transparent/menu-transparent.component";
import { MyStylesDirective } from "./Directives/my-styles-directive.directive";
import { NewsHeaderComponent } from "./components/news-header/news-header.component";
import { NewsListComponent } from "./components/news-list/news-list.component";
import { NewsTagsComponent } from "./components/news-tags/news-tags.component";
import { NgModule } from "@angular/core";
import { NgxPaginationModule } from "ngx-pagination";
import { NumberFormatterPipe } from "./pipes/numberFormatter.pipe";
import { ObjectiveCard1Component } from "./components/objective-card1/objective-card1.component";
import { ObjectivesCardsComponent } from "./components/objectives-cards/objectives-cards.component";
import { PopupOptionComponent } from "./components/popup-option/popup-option.component";
import { RouterModule } from "@angular/router";
import { SideNewComponent } from "./components/side-new/side-new.component";
import { Slider3itemsComponent } from "./components/slider3items/slider3items.component";
import { SliderComponent } from "./components/slider/slider.component";
import { SliderFixedImgTitleComponent } from "./components/slider-fixed-img-title/slider-fixed-img-title.component";
import { SliderHoverButtonsComponent } from "./components/slider-hover-buttons/slider-hover-buttons.component";
import { SliderImageSignContentComponent } from "./components/slider-image-sign-content/slider-image-sign-content.component";
import { SliderImgAnimateTitleComponent } from "./components/slider-img-animate-title/slider-img-animate-title.component";
import { SliderImgContentTitleComponent } from "./components/slider-img-content-title/slider-img-content-title.component";
import { SliderSignQouteImageComponent } from "./components/slider-sign-qoute-image/slider-sign-qoute-image.component";
import { SliderTitleDescSignComponent } from "./components/slider-title-desc-sign/slider-title-desc-sign.component";
import { SliderTitleDescriptionCardComponent } from "./components/slider-title-description-card/slider-title-description-card.component";
import { SocialMediaSharePopupComponent } from "./components/soical-media-share-popup/social-media-share-popup.component";
import { SpecialSpeakerHeaderComponent } from "./components/special-speaker-header/special-speaker-header.component";
import { SpecialSpeakersDetailsComponent } from "./components/special-speakers-details copy/special-speakers-details.component";
import { StatisticsCardsComponent } from "./components/statistics-cards/statistics-cards.component";
import { TextCardComponent } from "./components/text-card/text-card.component";
import { TitleBgCardComponent } from "./components/title-bg-card/title-bg-card.component";
import { TitleDescriptionCardComponent } from "./components/title-description-card/title-description-card.component";
import { TitleDescriptionCards2Component } from "./components/title-description-cards2/title-description-cards2.component";
import { TranslateModule } from "@ngx-translate/core";
import { TwoImageBigCardsComponent } from "./components/two-image-big-cards/two-image-big-cards.component";
import { TwoLogoComponent } from "./components/two-logo/two-logo.component";
import { WebSliderComponent } from "./components/web-slider/web-slider.component";
import { TimelineComponent } from "./components/timeline/timeline.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { PDFComponent } from "./components/pdf/pdf.component";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { VideoComponent } from "./components/video/video.component";
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { ContactInfoCardComponent } from "./components/contact-info-card/contact-info-card.component";
import { GoogleMapComponent } from "./components/google-map/google-map.component";
import { AgmCoreModule } from "@agm/core";
import { ReservationForm1Component } from "./components/reservation-form1/reservation-form1.component";
import { Advertising2Component } from "./components/advertising2/advertising2.component";
import { ReservationForm2Component } from "./components/reservation-form2/reservation-form2.component";
import { TitleSideDescriptionCardComponent } from "./components/title-side-description-card/title-side-description-card.component";
import { CmsComponent1GeneralComponent } from "./components/cms-component1-general/cms-component1-general.component";
import { CmsComponent2GeneralComponent } from "./components/cms-component2-general/cms-component2-general.component";
import { CmsComponent3GeneralComponent } from "./components/cms-component3-general/cms-component3-general.component";
import { CmsComponent4GeneralComponent } from "./components/cms-component4-general/cms-component4-general.component";
import { CmsComponent5GeneralComponent } from "./components/cms-component5-general/cms-component5-general.component";
import { CmsComponent6GeneralComponent } from "./components/cms-component6-general/cms-component6-general.component";
import { CmsComponent7GeneralComponent } from "./components/cms-component7-general/cms-component7-general.component";
import { CmsComponent8GeneralComponent } from "./components/cms-component8-general/cms-component8-general.component";
import { SliderModule } from "@syncfusion/ej2-angular-inputs";
import { Image2TitleDescriptionComponent } from "./components/image2-title-description/image2-title-description.component";
import { VideoTitleDescriptionComponent } from "./components/video-title-description/video-title-description.component";
import { Contactus1Component } from "./components/contactus1/contactus1.component";
import { OpeningtimeComponent } from "./components/openingtime/openingtime.component";
import { AppointmentcardInfoComponent } from "./components/appointmentcard-info/appointmentcard-info.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { BackgroundpopupComponent } from "./components/backgroundpopup/backgroundpopup.component";
import { DonationPopupComponent } from "./components/donation-popup/donation-popup.component";
import { AdvertisingImages3Component } from "./components/advertising-images3/advertising-images3.component";
import { ImgTitleDecGridComponent } from "./components/img-title-dec-grid/img-title-dec-grid.component";
import { ImagesGridComponent } from "./components/images-grid/images-grid.component";
import { ColorSizeFormComponent } from "./components/color-size-form/color-size-form.component";
import { ImgTitleDesc2Component } from "./components/img-title-desc2/img-title-desc2.component";
import { ImageBigTitleDescriptionComponent } from "./components/image-big-title-description/image-big-title-description.component";
import { ReservationForm3Component } from "./components/reservation-form3/reservation-form3.component";
import { DialogModule } from "primeng/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MenueButtonsComponent } from './components/menue-buttons/menue-buttons.component';
import { ListWithDescriptionComponent } from './components/list-with-description/list-with-description.component';
import { ListOfImagesComponent } from './components/list-of-images/list-of-images.component';
import { CommentsWithCardsComponent } from './components/comments-with-cards/comments-with-cards.component';
import { ProductsWithCardsComponent } from './components/products-with-cards/products-with-cards.component';
import { ImageWithBenfitsComponent } from './components/image-with-benfits/image-with-benfits.component';
import { Cards7Component } from './components/cards7/cards7.component';
import { ImageWithTwoListComponent } from './components/image-with-two-list/image-with-two-list.component';
import { ButtonIntoImageComponent } from './components/button-into-image/button-into-image.component';
import { ImagesWithFourTabsComponent } from './components/images-with-four-tabs/images-with-four-tabs.component';
import { BigBackgroundTitleDescriptionComponent } from './components/big-background-title-description/big-background-title-description.component';
import { TitleDescriptionBackgroundComponent } from './components/title-description-background/title-description-background.component';
import { PackagesCards4Component } from './components/packages-cards4/packages-cards4.component';
import { Contactus2Component } from './components/contactus2/contactus2.component';
import { Footer5Component } from './components/footer5/footer5.component';
import { ThreeLogoComponent } from './components/three-logo/three-logo.component';
import { Contactus3Component } from './components/contactus3/contactus3.component';
import { TitleDescListTwoImagesComponent } from './components/title-desc-list-two-images/title-desc-list-two-images.component';
import { Contactus4Component } from './components/contactus4/contactus4.component';
import { ImageTitleDescButtonComponent } from './components/image-title-desc-button/image-title-desc-button.component';
import { LogoComponent } from './components/logo/logo.component';
import { DescriptionWithFeaturesComponent } from './components/description-with-features/description-with-features.component';
import { BackgroundTitleDescriptionListComponent } from './components/background-title-description-list/background-title-description-list.component';
import { BrandsComponent } from './components/brands/brands.component';
import { CardsWithImageAndTitleComponent } from "./components/cards-with-image-and-title/cards-with-image-and-title.component";
import { ImageWithCardsComponent } from './components/image-with-cards/image-with-cards.component';
import { ImagesWithTitleComponent } from './components/images-with-title/images-with-title.component';
import { AboutItemComponent } from './components/about-item/about-item.component';
import { ImagesStaticPositionComponent } from './components/images-static-position/images-static-position.component';
import { ContactUsWithImageComponent } from './components/contact-us-with-image/contact-us-with-image.component';
import { ImageWithListFollowersComponent } from './components/image-with-list-followers/image-with-list-followers.component';
import { TwoImageWithDescriptionComponent } from './components/two-image-with-description/two-image-with-description.component';
import { FlagsComponent } from './components/flags/flags.component';
import { ListImageWithDescriptionComponent } from './components/list-image-with-description/list-image-with-description.component';
import { RandomImagesComponent } from './components/random-images/random-images.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { DescriptionWithButtonComponent } from './components/description-with-button/description-with-button.component';
import { ContactUs7Component } from './components/contact-us7/contact-us7.component';
import { CardPriceDescriptionComponent } from './components/card-price-description/card-price-description.component';
import { BrandsTitleComponent } from './components/brands-title/brands-title.component';
import { ReservationComponent } from './components/reservation/reservation.component';
import { ConversationComponent } from './components/conversation/conversation.component';
import { HeaderBackgroundComponent } from './components/header-background/header-background.component';
import { CircleSide3Component } from './components/circle-side3/circle-side3.component';
import { ImagesGrid2Component } from './components/images-grid2/images-grid2.component';
import { Contactus5Component } from './components/contactus5/contactus5.component';
import { DateCards4Component } from './components/date-cards4/date-cards4.component';
import { TitleDescSearchImageComponent } from './components/title-desc-search-image/title-desc-search-image.component';
import { AboutUs3Component } from './components/about-us3/about-us3.component';
import { FAQComponent } from './components/faq/faq.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { HeaderButtonComponent } from './components/header-button/header-button.component';
import { ThreeBigImagesTitleComponent } from './components/three-big-images-title/three-big-images-title.component';
import { Contactus6Component } from './components/contactus6/contactus6.component';
import { ReservationForm4Component } from './components/reservation-form4/reservation-form4.component';
import { Footer6Component } from './components/footer6/footer6.component';
import { SliderDescSearchComponent } from './components/slider-desc-search/slider-desc-search.component';
import { AboutListImageComponent } from './components/about-list-image/about-list-image.component';
import { DateCards3ImageComponent } from './components/date-cards3-image/date-cards3-image.component';
import { CardsFilterComponent } from './components/cards-filter/cards-filter.component';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { BgTitleIconsComponent } from './components/bg-title-icons/bg-title-icons.component';
import { BrandsCarsComponent } from './components/brands-cars/brands-cars.component';
import { IconsTitleDiscComponent } from './components/icons-title-disc/icons-title-disc.component';
import { CardsWithSearchComponent } from './components/cards-with-search/cards-with-search.component';
import { ImageTitleComponent } from './components/image-title/image-title.component';
import { BenfitsWithCardsComponent } from './components/benfits-with-cards/benfits-with-cards.component';
import { FooterCopyrightComponent } from './components/footer-copyright/footer-copyright.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TitleDescriptionSideImageComponent } from './components/title-description-side-image/title-description-side-image.component';
import { Contactus7Component } from './components/contactus7/contactus7.component';
import { ImageSlider3Component } from './components/image-slider3/image-slider3.component';
import { GetTouchComponent } from './components/get-touch/get-touch.component';
import { TitleDescImage3Component } from './components/title-desc-image3/title-desc-image3.component';
import { HeaderWithTwoButtonComponent } from './components/header-with-two-button/header-with-two-button.component';
import { CardProfileComponent } from "../card-profile/card-profile.component";
import { HeaderTitleDescComponent } from './components/header-title-desc/header-title-desc.component';
import { HowtoImageDescComponent } from "./components/how-to-image-desc/how-to-image-desc.component";

import { SeparatorComponent } from './components/separator/separator.component';
import { EditComponentPopupComponent } from './edit-component-popup/edit-component-popup.component';

import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {ButtonModule} from 'primeng/button';
import { GridStyleDirective } from './Directives/grid-style.directive';
import { CardBgLabelComponent } from './components/card-bg-label/card-bg-label.component';
import { SliderStyleDirective } from './Directives/slider-style.directive';
//Custom Modules and components

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  declarations: [
    SocialMediaSharePopupComponent,
    EtaamFooterComponent,
    AdvertisingImages3Component,
    ImgTitleDecGridComponent,
    ImagesGridComponent,
    DonationPopupComponent,
    BackgroundpopupComponent,
    HeaderComponent,
    CardOpportunityComponent,
    DateFormatterPipe,
    CardEventComponent,
    ArticleCardComponent,
    LoaderComponent,
    NumberFormatterPipe,
    ButtonCards2Component,
    ButtonCards4Component,
    Cards3Component,
    Cards4Component,
    ImgTitleDescriptionCardComponent,
    SliderComponent,
    SliderTitleDescriptionCardComponent,
    TitleDescriptionCardComponent,
    TitleDescriptionCards2Component,
    CardsComponent,
    ImageTitleSubtitleDescriptionButtonCardComponent,
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    Footer2Component,
    Footer3Component,
    Card3WhiteBgComponent,
    CmsMostWatchedComponentComponent,
    CmsGeneralCardComponent,
    CmsComponent1Component,
    CmsComponent2Component,
    CmsComponent3Component,
    CmsComponent4Component,
    CmsComponent5Component,
    CmsComponent6Component,
    CmsComponent7Component,
    CmsComponent8Component,
    TwoImageBigCardsComponent,
    ImageSliderComponent,
    IconTitleDescCard3Component,
    IconBgTitleDescCard3Component,
    SideNewComponent,
    BigImageSideComponent,
    ImageTitleDescriptionComponent,
    CardsSliderComponent,
    BigVideoComponent,
    ObjectivesCardsComponent,
    AdvertisingComponent,
    MediaComponent,
    EventBigCardComponent,
    TwoLogoComponent,
    LogoSliderComponent,
    DocumentaryComponent,
    GalleryComponent,
    GridCardsComponent,
    Cards6SliderComponent,
    TitleBgCardComponent,
    TextCardComponent,
    Cards4ObjectivesCardComponent,
    ImageTitleDescHorizontzalCardsComponent,
    NewsHeaderComponent,
    NewsListComponent,
    NewsTagsComponent,
    FaqHeaderComponent,
    FaqQuestionsComponent,
    ObjectiveCard1Component,
    FourLogosImageComponent,
    ImagePararaphComponent,
    SpecialSpeakerHeaderComponent,
    SpecialSpeakersDetailsComponent,
    AboutUsHeaderComponent,
    AboutUsDescriptionComponent,
    AboutUsOverviewComponent,
    Circles3Component,
    Circles4Component,
    DateCards3Component,
    StatisticsCardsComponent,
    Card3VerticalComponent,
    CardLocationComponent,
    Header4Component,
    Header5Component,
    WebSliderComponent,
    Slider3itemsComponent,
    Cards6Component,
    Gallery3Component,
    GetintouchComponent,
    Footer4Component,
    EventsCards3Component,
    CardsListComponent,
    CardBgLabelComponent,
    CardsTitleListComponent,
    GalleryListComponent,
    AboutUsTornadoComponent,
    PopupOptionComponent,
    AcademyRegisterComponent,
    AcademyMenuComponent,
    AcademyRegisterNoBgComponent,
    MenuTransparentComponent,
    SliderTitleDescSignComponent,
    SliderImageSignContentComponent,
    SliderSignQouteImageComponent,
    SliderImgContentTitleComponent,
    SliderImgAnimateTitleComponent,
    SliderFixedImgTitleComponent,
    SliderHoverButtonsComponent,
    LinksFooterComponent,
    GridCards5Component,

    MyStylesDirective,
    TimelineComponent,
    LineChartComponent,
    PDFComponent,
    DataTableComponent,
    VideoComponent,
    HeaderSideComponent,
    ContactInfoCardComponent,
    GoogleMapComponent,
    ReservationForm1Component,
    ColorSizeFormComponent,
    Advertising2Component,
    ReservationForm2Component,
    ImgTitleDesc2Component,
    ImageBigTitleDescriptionComponent,
    TitleSideDescriptionCardComponent,
    CmsComponent1GeneralComponent,
    CmsComponent2GeneralComponent,
    CmsComponent3GeneralComponent,
    CmsComponent4GeneralComponent,
    CmsComponent5GeneralComponent,
    CmsComponent6GeneralComponent,
    CmsComponent7GeneralComponent,
    CmsComponent8GeneralComponent,
    Image2TitleDescriptionComponent,
    VideoTitleDescriptionComponent,
    Contactus1Component,
    OpeningtimeComponent,
    AppointmentcardInfoComponent,
    ReservationForm3Component,
    MenueButtonsComponent,
    ListWithDescriptionComponent,
    ListOfImagesComponent,
    CommentsWithCardsComponent,
    ProductsWithCardsComponent,
    ImageWithBenfitsComponent,
    Cards7Component,
    ImageWithTwoListComponent,
    ButtonIntoImageComponent,
    ImagesWithFourTabsComponent,
    BigBackgroundTitleDescriptionComponent,
    TitleDescriptionBackgroundComponent,
    PackagesCards4Component,
    Contactus2Component,
    Footer5Component,
    ThreeLogoComponent,
    Contactus3Component,
    TitleDescListTwoImagesComponent,
    Contactus4Component,
    ImageTitleDescButtonComponent,
    LogoComponent,
    DescriptionWithFeaturesComponent,
    CardsWithImageAndTitleComponent,
    BackgroundTitleDescriptionListComponent,
    BrandsComponent,
    ImageWithCardsComponent,
    ImagesWithTitleComponent,
    AboutItemComponent,
    ImagesStaticPositionComponent,
    ContactUsWithImageComponent,
    ImageWithListFollowersComponent,
    TwoImageWithDescriptionComponent,
    FlagsComponent,
    ListImageWithDescriptionComponent,
    RandomImagesComponent,
    BlogsComponent,
    DescriptionWithButtonComponent,
    ContactUs7Component,
    CardPriceDescriptionComponent,
    BrandsTitleComponent,
    ReservationComponent,
    ConversationComponent,
    HeaderBackgroundComponent,
    CircleSide3Component,
    ImagesGrid2Component,
    Contactus5Component,
    DateCards4Component,
    TitleDescSearchImageComponent,
    AboutUs3Component,
    FAQComponent,
    ProductDetailsComponent,
    HeaderButtonComponent,
    ThreeBigImagesTitleComponent,
    Contactus6Component,
    ReservationForm4Component,
    Footer6Component,
    SliderDescSearchComponent,
    AboutListImageComponent,
    DateCards3ImageComponent,
    CardsFilterComponent,
    ImagesListComponent,
    BgTitleIconsComponent,
    BrandsCarsComponent,
    IconsTitleDiscComponent,
    CardsWithSearchComponent,
    ImageTitleComponent,
    BenfitsWithCardsComponent,
    FooterCopyrightComponent,
    BreadcrumbComponent,
    TitleDescriptionSideImageComponent,
    Contactus7Component,
    ImageSlider3Component,
    HowtoImageDescComponent,
    GetTouchComponent,
    TitleDescImage3Component,
    HeaderWithTwoButtonComponent,
    CardProfileComponent,
    HeaderTitleDescComponent,
    SeparatorComponent,
    EditComponentPopupComponent,
    GridStyleDirective,
    CardBgLabelComponent,
    SliderStyleDirective

  ],
  exports: [
    MyStylesDirective,
    CarouselModule,
    TranslateModule,
    MatStepperModule,
    FormsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule,

    SocialMediaSharePopupComponent,
    EtaamFooterComponent,
    HeaderComponent,
    CardOpportunityComponent,
    CardEventComponent,
    ArticleCardComponent,
    NgxPaginationModule,
    DateFormatterPipe,
    NumberFormatterPipe,
    LoaderComponent,
    ButtonCards2Component,
    ButtonCards4Component,
    Cards3Component,
    Cards4Component,
    ImgTitleDescriptionCardComponent,
    SliderComponent,
    SliderTitleDescriptionCardComponent,
    TitleDescriptionCardComponent,
    TitleDescriptionCards2Component,
    CardsComponent,
    ImageTitleSubtitleDescriptionButtonCardComponent,
    Card3WhiteBgComponent,
    TwoImageBigCardsComponent,
    CmsMostWatchedComponentComponent,
    ImageSliderComponent,
    IconTitleDescCard3Component,
    IconBgTitleDescCard3Component,
    SpecialSpeakerHeaderComponent,
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    Footer2Component,
    Footer3Component,
    CmsGeneralCardComponent,
    CmsComponent1Component,
    CmsComponent2Component,
    CmsComponent3Component,
    CmsComponent4Component,
    CmsComponent5Component,
    CmsComponent6Component,
    CmsComponent7Component,
    CmsComponent8Component,
    SideNewComponent,
    BigImageSideComponent,
    ImageTitleDescriptionComponent,
    CardsSliderComponent,
    BigVideoComponent,
    ObjectivesCardsComponent,
    AdvertisingComponent,
    MediaComponent,
    EventBigCardComponent,
    TwoLogoComponent,
    LogoSliderComponent,
    DocumentaryComponent,
    GalleryComponent,
    GridCardsComponent,
    Cards6SliderComponent,
    TitleBgCardComponent,
    TextCardComponent,
    Cards4ObjectivesCardComponent,
    ImageTitleDescHorizontzalCardsComponent,
    NewsListComponent,
    NewsTagsComponent,
    NewsHeaderComponent,
    FaqHeaderComponent,
    FaqQuestionsComponent,
    ObjectiveCard1Component,
    FourLogosImageComponent,
    ImagePararaphComponent,
    SpecialSpeakersDetailsComponent,
    AboutUsHeaderComponent,
    AboutUsDescriptionComponent,
    AboutUsOverviewComponent,
    Circles3Component,
    Circles4Component,
    DateCards3Component,
    StatisticsCardsComponent,
    Card3VerticalComponent,
    CardLocationComponent,
    Header4Component,
    Header5Component,
    WebSliderComponent,
    Slider3itemsComponent,
    Cards6Component,
    Gallery3Component,
    GetintouchComponent,
    Footer4Component,
    EventsCards3Component,
    CardsListComponent,
  
    CardsTitleListComponent,
    GalleryListComponent,
    AboutUsTornadoComponent,
    PopupOptionComponent,
    AcademyRegisterComponent,
    AcademyMenuComponent,
    AcademyRegisterNoBgComponent,
    MenuTransparentComponent,
    SliderTitleDescSignComponent,
    SliderImageSignContentComponent,
    SliderSignQouteImageComponent,
    SliderImgContentTitleComponent,
    SliderImgAnimateTitleComponent,
    SliderFixedImgTitleComponent,
    SliderHoverButtonsComponent,
    LinksFooterComponent,
    GridCards5Component,
    TimelineComponent,
    LineChartComponent,
    PDFComponent,
    DataTableComponent,
    VideoComponent,
    HeaderSideComponent,
    ContactInfoCardComponent,
    GoogleMapComponent,
    ReservationForm1Component,
    Advertising2Component,
    ReservationForm2Component,
    TitleSideDescriptionCardComponent,
    CmsComponent1GeneralComponent,
    CmsComponent2GeneralComponent,
    CmsComponent3GeneralComponent,
    CmsComponent4GeneralComponent,
    CmsComponent5GeneralComponent,
    CmsComponent6GeneralComponent,
    CmsComponent7GeneralComponent,
    CmsComponent8GeneralComponent,
    Image2TitleDescriptionComponent,
    VideoTitleDescriptionComponent,
    Contactus1Component,
    OpeningtimeComponent,
    AppointmentcardInfoComponent,
     AdvertisingImages3Component,
    ColorSizeFormComponent,
    ImgTitleDecGridComponent,
    ImgTitleDesc2Component,
    ImageBigTitleDescriptionComponent,
    ImagesGridComponent,
    ReservationForm3Component,
    CommentsWithCardsComponent,
    BigBackgroundTitleDescriptionComponent,
    TitleDescriptionBackgroundComponent,
    PackagesCards4Component,
    MenueButtonsComponent,
    ListWithDescriptionComponent,
    ListOfImagesComponent,
    CommentsWithCardsComponent,
    ProductsWithCardsComponent,
    ImageWithBenfitsComponent,
    Cards7Component,
    ImageWithTwoListComponent,
    ButtonIntoImageComponent,
    ImagesWithFourTabsComponent,
    Contactus2Component,
    Footer5Component,
    ThreeLogoComponent,
    Contactus3Component,
    TitleDescListTwoImagesComponent,
    Contactus4Component,
    ImageTitleDescButtonComponent,
    LogoComponent,
    DescriptionWithFeaturesComponent,
    CardsWithImageAndTitleComponent,
    BackgroundTitleDescriptionListComponent,
    BrandsComponent,
    ImageWithCardsComponent,
    ImagesWithTitleComponent,
    AboutItemComponent,
    ImagesStaticPositionComponent,
    ContactUsWithImageComponent,

    ImageWithListFollowersComponent,
    TwoImageWithDescriptionComponent,
    FlagsComponent,
    ListImageWithDescriptionComponent,
    RandomImagesComponent,
    BlogsComponent,
    DescriptionWithButtonComponent,
    ContactUs7Component,
    CardPriceDescriptionComponent,
    BrandsTitleComponent,
    ReservationComponent,
    ConversationComponent,
    HeaderBackgroundComponent,
    CircleSide3Component,
    ImagesGrid2Component,
    Contactus5Component,
    DateCards4Component,
    TitleDescSearchImageComponent,
    AboutUs3Component,
    FAQComponent,
    ProductDetailsComponent,
    HeaderButtonComponent,
    ThreeBigImagesTitleComponent,
    Contactus6Component,
    ReservationForm4Component,
    Footer6Component,
    SliderDescSearchComponent,
    AboutListImageComponent,
    DateCards3ImageComponent,
    CardsFilterComponent,

    ImagesListComponent,
    BgTitleIconsComponent,
    BrandsCarsComponent,
    IconsTitleDiscComponent,
    CardsWithSearchComponent,
    ImageTitleComponent,
    BenfitsWithCardsComponent,
    FooterCopyrightComponent,

    BreadcrumbComponent,
    TitleDescriptionSideImageComponent,
    Contactus7Component,
    ImageSlider3Component,
    HowtoImageDescComponent,
    GetTouchComponent,
    TitleDescImage3Component,
    HeaderWithTwoButtonComponent,
    CardProfileComponent,
    HeaderTitleDescComponent,
    SeparatorComponent,
    EditComponentPopupComponent,
    CardBgLabelComponent
  ],
  imports: [
    CommonModule,

    SwiperModule,
    DialogModule,
    DynamicDialogModule,
    ButtonModule,
    CarouselModule,
    AuthenticationModule,
    MatStepperModule,
    MatDatepickerModule,
    SliderModule,
    RouterModule,
    MatSelectModule,
    DropdownModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatOptionModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD3HziFPDuj312mPv_J7jkTqsidehDPX6M",
      // libraries: ['places'],
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    LoaderService,
  ],
})
export class SharedModule {}
