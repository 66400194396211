<div [appMyStyles]="component">
  <div class="row">
    <app-header-title-desc [article]="component"></app-header-title-desc>
  </div>
  <div
    class="row"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    *ngIf="fourCirclesData?.length>0"
  >
    <div
      class="col-md-3 col-sm-6 col-xs-12 big-div "
      style="cursor: pointer"
      *ngFor="let item of fourCirclesData | slice : 0 : component.items_number"
    >
      <img
       
        class="circle-img"
        src="{{ item.image }}"
        (click)="navigateToShow(item.id)"/>
 
  
      <h6
        class="names"
        style="text-align: center"
        *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
        [innerHtml]="item.title_detailed"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      ></h6>
      <p
        class="member-paragraph"
        *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>
  
      <h6
        class="names"
        style="text-align: center"
        *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
      >
        {{ item.title }}
      </h6>
      <p
        class="member-paragraph"
        *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
      >
        {{ item.description }}
      </p>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="fourCirclesData?.length == 0">
      <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
  
</div>


