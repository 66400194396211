<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div
  class="row mb-5"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  [appMyStyles]="component"
  
>
<!-- for center content -->
<ng-container *ngIf="component?.Style_content_position == 0">
  <div
    class="mar-bottom-col " [ngClass]="col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : component?.items_number;index as i"
  >
  

    <div class="card hover-style"  [appGridStyle]="component">
      <button  class="btn btn-half text-center" *ngIf="item?.price != null && item?.image_tags != null"
      [ngStyle]="{
         'border-color':websiteSecColor,'color':websiteSecColor}">
       {{ item?.price }}
     </button>
      <img [ngClass]="component?.Style_image_shape=='1'?'card-img-top':'card-img'" [src]="item?.image" />

   

      <h6  class="px-4"
      [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      >
        {{ item.title }}
      </h6>

      <p  class="desc-details"
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
      >
        {{ item.description }}
      </p>

      <button  *ngIf="component?.component_buttons?.length>0"
      [ngStyle]="{
        'background-color': component?.component_buttons[i]?.button_background_color,
                    color: component?.component_buttons[i]?.button_color,
                    'border-color':component?.component_buttons[i]?.button_background_color,
                    'font-size':component?.component_buttons[i]?.button?.font_size,
                    'font-weight':component?.component_buttons[i]?.button?.font_weight

      }"
        (click)="navigateToShow(item.id)"
       
        class="read-more" 
      >
      <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[i]?.button_text}} </ng-container> 
      <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[i]?.button_name}} </ng-container> 
 
      </button>
    </div>
    <div class="top-left">
    
     
    </div>
  </div>
</ng-container>
<!-- for side content -->

<ng-container *ngIf="component?.Style_content_position != 0">
  <div
  class="mar-bottom-col col-lg-6 col-sm-12 mb-3" 
  *ngFor="let item of FourbuttoncardsData ;index as i"
>


  <div class="card-side"  [appGridStyle]="component">
   
    <img [ngClass]="component.Style_image_shape=='1'?'card-img-side':'card-img'" [src]="item?.image" />

 
    <div class="card-content-side">
        <h6  class="px-4"
    [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
    >
      {{ item.title }}
    </h6>
<div class="d-flex">
    <p  class="desc-details"
    [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
    >
      {{ item.description }}
    </p>

    <button  *ngIf="component?.component_buttons?.length>0"
    [ngStyle]="{
      'background-color': component?.component_buttons[i]?.button_background_color,
                  color: component?.component_buttons[i]?.button_color,
                  'border-color':component?.component_buttons[i]?.button_background_color,
                  'font-size':component?.component_buttons[i]?.button?.font_size,
                  'font-weight':component?.component_buttons[i]?.button?.font_weight

    }"
      (click)="navigateToShow(item.id)"
     
      class="read-more read-more-side"
    >
    <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[i]?.button_text}} </ng-container> 
    <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[i]?.button_name}} </ng-container> 

    </button>
</div>
  
    </div>
  
  </div>
</div>

</ng-container>

</div>

<div class="alert alert-warning text-center" role="alert" *ngIf="FourbuttoncardsData?.length == 0" >
  <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>
