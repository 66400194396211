<div class="container-fluid" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-12 col-sm-12 col-xs-12 web-slider" style="width: 100%">
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img">
                <img  [src]="slide.image" />
                <div  class="text-overlay"
                 [ngClass]="[
               
                article?.content_position_vertical=='bottom' ? 'bottom' : ''
            ]">
                
                  <h1
                  
                  [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
                    class="card-title"
                  >
                    {{ slide.title }}
                  </h1>
                  <p *ngIf=" article?.content_position_vertical!='bottom'"
                  [ngStyle]="{
                    color: article.description_color,
                    'font-size': article.description_size,
                    'font-weight': article?.data_desc_weight
                  }"
                  >
                    {{ slide.description }}
                  </p>

                 
                </div>
    

              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination" *ngIf="article?.slider_icon != 1 "></div>
          <div class="swiper-button-prev" *ngIf="article?.slider_icon == 1">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="swiper-button-next"  *ngIf="article?.slider_icon == 1">
            <i class="fas fa-chevron-right"></i>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>
