<div
  [appMyStyles]="component"
 
>
    <app-header-title-desc [article]="component"></app-header-title-desc>
</div>



<div class="top-container" [appMyStyles]="component" [ngStyle]="{ 'background-image': 'url(' + component.component_background_image + ')' }" >
  

  <div  *ngIf="imageTitleDescriptionData?.length > 0 "
    class="row mx-4 my-2" [ngStyle]="{'border':component?.border == 0 ?'1px solid':'none','border-color':component?.Style_border != null ?component?.Style_border : websiteMainColor }"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    (click)="navigateToShow(imageTitleDescriptionData[0]?.id)"
  >
    <div
      *ngIf="component?.image_position == 'left'"
      class="col-md-4 col-sm-12 col-xs-12"
    >
      <img
        class="program-img my-3"
        src="{{ imageTitleDescriptionData[0]?.image }}"
      />
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 padd" [ngClass]="component?.Style_content_position == 0   ? 'text-center' : ''">



      <p [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color,'font-weight':component?.component_title_weight
}"
       >{{ imageTitleDescriptionData[0]?.title }}</p>
      
      <p [ngStyle]="{'font-size': component?.desc_font_size,'color': component?.description_color, 'font-weight':component?.component_desc_weight}">
        {{ imageTitleDescriptionData[0]?.description }}
      </p>
      <button  *ngIf="component?.component_buttons?.length>0"
        [ngStyle]="{
          'background-color': component?.component_buttons[0]?.button_background_color,
                      color: component?.component_buttons[0]?.button_color,
                      'border-color':component?.component_buttons[0]?.button_background_color,
                      'font-size':component?.component_buttons[0]?.button?.font_size,
                      'font-weight':component?.component_buttons[0]?.button?.font_weight
  
        }"
          
         
          class="read-more" 
        >
        <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
        <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_name}} </ng-container> 
   
        </button>
    </div>

    <div *ngIf="component?.image_position == 'right'" class="col-md-4 col-sm-12 col-xs-12">
      <img class="program-img2" src="{{ imageTitleDescriptionData[0]?.image }}" />
    </div>

  </div>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleDescriptionData?.length == 0 || imageTitleDescriptionData == undefined ">
    <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>

