<div
  [appMyStyles]="article"
  class="d-flex justify-content-center align-items-center"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
  

  <div
    class=" col-12  brand-container"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <img  [ngClass]="
    article.Style_image_shape == '0'
      ? 'slider-img-circle'
      : 'slider-img-square'
  "
      *ngFor="let item of ImageTitleData"
    
      src="{{ item.image }}"
    />
  </div>
</div>
