<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row d-flex justify-content-center about-us-slider"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <p-carousel
  [circular]="false"
  [autoplayInterval]="1000000"

    [value]="ImageTitleData"
    [numVisible]="1"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions"
  >
  <ng-template let-slide pTemplate="item" >
    <div class="row align-items-center">
    <img class="col-lg-3 col-md-3 col-xs-4  slide-image" [src]="slide.image" />
    <div class="col-lg-7 col-md-6 col-xs-4 col-sm-4">
      <h5  [ngStyle]="{
        'font-size': article?.title_size,
        color: article?.title_color,
        'font-weight': article?.data_title_weight
      }"
      >{{slide.title}}</h5>
      <div class="row d-flex bg px-3 py-2 rounded-lg flex-nowrap">
        <p
          class="mx-3"
          [ngStyle]="{
            'font-size': article?.description_size,
            color: article?.description_color,
            'font-weight': article?.data_desc_weight
          }"
       
        >{{slide.description}}</p>
       
      </div>
    </div>
  </div>
    </ng-template>
  </p-carousel>

    
  </div>
</div>
