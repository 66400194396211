<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"

> 
  <div
    class="col-md-12 col-sm-12 col-xs-12 text-center"
    *ngIf="article?.component_title_position == 'center'">
    <ng-container *ngIf="article?.component_title && article?.show_title == 1">
        <h4 class="mb-3"
      [ngStyle]="{ 'color': article?.title_font_color, 'font-size': article?.title_font_size ,'font-weight':article?.component_title_weight}"
    >
      {{ article?.component_title }}
    </h4>
    </ng-container>
    <h6 *ngIf="article?.component_description && article?.hide_description == 0" [ngStyle]="{ 'color': article?.desc_font_color, 'font-size': article?.desc_font_size ,'font-weight':article?.component_desc_weight}">
      {{ article?.component_description  }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="article?.component_title_position != 'center'">
    <div class="separator" [ngClass]="lang == 'ar_container'?'flex-row-reverse' :'flex-row'">
    <ng-container *ngIf="article?.component_title">
   <!-- <span class="line short"  [ngStyle]="{'background-color': websiteMainColor}" ></span> -->

        <h4 class="mb-2"
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: article?.title_font_color,
          'font-size': article?.title_font_size
          ,'font-weight':article?.component_title_weight
        }"
      >
        {{ article?.component_title }}
      </h4>
   <!-- <span class="line"  [ngStyle]="{'background-color': websiteMainColor}"
   [style.background]='"linear-gradient(to right,"+ websiteMainColor + "," + websiteSecColor + "," + opacityColor+")"'></span>
       -->

    
  </ng-container> 
  
  <ng-container *ngIf="article?.component_title">

      <h4 class="mb-2"
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          'color': article?.title_font_color,
          'font-size': article?.title_font_size
        }"
      >
        {{ article?.component_title }}
      </h4>
    </ng-container>   
  </div>
    <h6 *ngIf="article?.component_description " [ngStyle]="{ color: article?.desc_font_color, 'font-size': article?.desc_font_size,'font-weight':article?.component_desc_weight }">
      {{ article?.component_description  }}
    </h6>
  </div>
</div>
