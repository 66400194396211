<div class="row title-desc" >

  <app-header-title-desc [article]="article" ></app-header-title-desc>

</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [appMyStyles]="article"
  *ngIf="Adertising_Data?.length==6"
>
    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[0]?.id)"
    >
      <img
        *ngIf="Adertising_Data[0]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[0]?.image}}"
      />     
    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px;padding-top:30px"
    (click)="navigateToShow(Adertising_Data[1]?.id)"
    >
      <img
        *ngIf="Adertising_Data[1]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[1]?.image}}"

      />     
    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[2]?.id)"
    >
      <img
        *ngIf="Adertising_Data[2]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[2]?.image}}"
      />     

    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[3]?.id)"
    >
      <img
        *ngIf="Adertising_Data[3]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[3]?.image}}"
      />     

    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px;padding-top:30px"
    (click)="navigateToShow(Adertising_Data[4]?.id)"
    >
      <img
        *ngIf="Adertising_Data[4]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[4]?.image}}"
      />     

    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[5]?.id)"
    >

      <img
        *ngIf="Adertising_Data[5]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[5]?.image}}"
      />     

    </div>

</div>
<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [appMyStyles]="article"
  *ngIf="Adertising_Data?.length==7"
>
    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[0]?.id)"
    >
      <img
        *ngIf="Adertising_Data[0]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[0]?.image}}"
      />     
    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px;"
    (click)="navigateToShow(Adertising_Data[1]?.id)"
    >
      <img
        *ngIf="Adertising_Data[1]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[1]?.image}}"

      />     
    </div>

    <div
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[2]?.id)"
    >
      <img
        *ngIf="Adertising_Data[2]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[2]?.image}}"
      />     

    </div>

    <div
    class="col-md-3 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[3]?.id)"
    >
      <img
        *ngIf="Adertising_Data[3]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[3]?.image}}"
      />     

    </div>

    <div
    class="col-md-3 col-sm-12 col-xs-12"
    style="margin-bottom: 15px;"
    (click)="navigateToShow(Adertising_Data[4]?.id)"
    >
      <img
        *ngIf="Adertising_Data[4]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[4]?.image}}"
      />     

    </div>

    <div
    class="col-md-3 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[5]?.id)"
    >

      <img
        *ngIf="Adertising_Data[5]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[5]?.image}}"
      />     

    </div>
    <div
    class="col-md-3 col-sm-12 col-xs-12"
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[6]?.id)"
    >

      <img
        *ngIf="Adertising_Data[6]?.type == 'image'"
        class="cover-img-3"
        src="{{Adertising_Data[6]?.image}}"
      />     

    </div>

</div>
<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [appMyStyles]="article" 
  *ngIf="Adertising_Data?.length==8"
>
<div class="col-md-4 col-sm-12 col-xs-12 d-flex image-tall">
 <div
   
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[0]?.id)"
    >
      <img
        *ngIf="Adertising_Data[0]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[0]?.image}}"
      />     
 </div>

    <div
  
    style="margin-bottom: 15px;padding-top:30px"
    (click)="navigateToShow(Adertising_Data[1]?.id)"
    >
      <img
        *ngIf="Adertising_Data[1]?.type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[1]?.image}}"

      />     
    </div>
</div>
   
<div class="col-md-4 col-sm-12 col-xs-12 d-flex image-tall-2">
    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[2]?.id)"
    >
      <img
        *ngIf="Adertising_Data[2]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[2]?.image}}"
      />     

    </div>

    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[3]?.id)"
    >
      <img
        *ngIf="Adertising_Data[3]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[3]?.image}}"
      />     

    </div>
    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[4]?.id)"
    >
      <img
        *ngIf="Adertising_Data[4]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[4]?.image}}"
      />     
    </div>
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 d-flex image-tall-2">
    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[5]?.id)"
    >
      <img
        *ngIf="Adertising_Data[5]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[5]?.image}}"
      />     

    </div>

    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[6]?.id)"
    >
      <img
        *ngIf="Adertising_Data[6]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[6]?.image}}"
      />     

    </div>
    <div
  
    style="margin-bottom: 15px"
    (click)="navigateToShow(Adertising_Data[7]?.id)"
    >
      <img
        *ngIf="Adertising_Data[7]?.type == 'image'"
        class="cover-img-2"
        src="{{Adertising_Data[7]?.image}}"
      />     
    </div>
  </div>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="Adertising_Data?.length == 0">
    <p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="Adertising_Data?.length < 6 ">
  <p><b>#{{article?.id}}</b> {{'NumberAlarm' | translate}}  </p>
</div>