<header [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="row full-width d-flex align-items-center ">
    
      <div class="col-md-6 col-sm-5 col-xs-12 text-start">
        <a class="navbar-brand">
          <img class="logo" [src]="website_logo" />
          <img
            *ngIf="website_logo == '' || website_logo == null"
            class="logo"
            src="assets/images/Appoxlogo.PNG"
          />
        </a>
      </div>
      <div  class="col-md-3 col-sm-3 col-xs-12">
        <ul class="navbar-nav d-flex align-items-center w-100">
          <li [ngStyle]="{ 'transform': lang.key == 'ar' ? 'scaleX(-1)' : 'none' }">
            <p > <i [ngStyle]="{
              color: websiteSecColor,
            
             
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }" class="fa far fa-envelope call"></i> 
            </p>
          </li>
          <li class="phone-data" [ngStyle]="{
            color: Header_Color,
           'font-size' : decreaseFontSize(Header_font_size),
           
           'font-family':text_type,
           'font-weight': text_weight,
           'white-space': 'nowrap'
           }">
            <p> 
              {{'Info@Healthcare.com' | translate}} </p>
              <p > {{'Email' |translate}}</p>
           
          </li>


        </ul>
      </div>
      <div  class="col-md-3 col-sm-3 col-xs-12">
        <ul class="navbar-nav d-flex align-items-center w-100">
          <li [ngStyle]="{ 'transform': lang.key == 'ar' ? 'scaleX(-1)' : 'none' }">
            <p > <i [ngStyle]="{
              color: websiteSecColor,
            
             
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }" class="fa fa-phone call"></i> 
            </p>
          </li>
          <li class="phone-data" [ngStyle]="{
            color: Header_Color,
           'font-size' : decreaseFontSize(Header_font_size),
           
           'font-family':text_type,
           'font-weight': text_weight,
           'white-space': 'nowrap'
           }">
            <p> 
              +252-335-9558 </p>
              <p > {{'Contact us' |translate}}</p>
           
          </li>


        </ul>
      </div>
    
   
    </div>
  </nav>

  <nav
    class="navbar navbar-expand-lg navbar-light p-0"
   
  >
    <div class="row full-width align-items-center">
      <div class="col-md-2 col-sm-12 col-xs-12 text-center">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="col-md-8 col-sm-12 col-xs-12 text-center">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
              <li
                class="nav-item"
                *ngIf="item.menu_design.header == 1"
                [ngStyle]="{ 'margin-inline': '0.5rem' }"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                  
                    'font-size' : Header_font_size ,
                    'text-transform': Text_Style,
                    'font-family':text_type,
                   'font-weight': text_weight,
                    'color':
                      hoverHeaderItem == i ||
                      itemSelectedFromHeader == item.name
                        ? websiteMainColor
                        : Header_Color
                  }"
                  class="nav-link"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}</a
                >
              </li>
            </ng-container>

 
          </ul>
        </div>
      </div>
         <!-- <div class="col-md-2 col-sm-12 col-xs-12">
        <div class="social">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a
                *ngIf="link.value != null"
                class="btn-floating btn-sm rgba-white-slight"
                href="{{ link?.value }}"
                target="_blank"
              >
                <img [src]="link?.icon" alt="" />
              </a>
            </li>

          </ul>
        </div>
      </div> -->
      <div class="col-md-2 col-sm-12 col-xs-12">
        <div
        class="lang-section"
       
        (click)="lang.key == 'ar' ? changeLang('en') : changeLang('ar')"
      >
        <a class="d-flex" 
        [ngStyle]="{
          color: Header_Color,
         'font-size' : Header_font_size ,
         'font-family':text_type,
         'font-weight': text_weight,
         'white-space': 'nowrap'
         }"> {{ 'languageType'  | translate}} </a>
     <i class="fa fa-globe p-1" aria-hidden="true"  [ngStyle]="{
          color: Header_Color }"></i>
      </div>

      </div>
    </div>
  </nav>
 
</header>
