<header class="shadow" [ngStyle]="{ 'background-color': Header_Bg_Color }">
    <div class="full-width">
      <nav class="navbar navbar-expand-lg navbar-light">

        <a class="navbar-brand" *ngIf=" APP_ID != 398">
          <img class="logo" [src]="website_logo" />
         
        </a>
        <a class="navbar-brand" *ngIf=" APP_ID == 398">
          <img class="logo2" [src]="website_logo" />
                 
        </a>

        <div class="mobile-only pull-right">
          <div class="nav-item dropdown pull-right" *ngIf="token != null">
            <a
              class="nav-link notification-button"
              href="#"
              id="notification"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="num">{{ notification_count | numberFormatter }}</span>
            </a>
            <div
              class="dropdown-menu notification-list"
              aria-labelledby="notification"
            >
              <li *ngFor="let notificationItem of notificationList">
                <a
                  class="nav-link dropdown-item {{
                    notificationItem.read_at ? 'notification-read' : ''
                  }}"
                  routerLink="user-area/orders"
                  id="notifi"
                  (click)="readselected(notificationItem.id)"
                >
                  <span class="float-right">
                    {{ notificationItem.notification_text }}
                  </span>
  
                  <small class="float-right time">
                    {{ notificationItem.since }}
                  </small>
                </a>
              </li>
            </div>
          </div>
          
        </div>
        
        <button *ngIf=" APP_ID != 383"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
      >
        <span class="navbar-toggler-icon" 
              [ngStyle]="{
                    'background-color': Header_Color
              }">
        </span>
        </button>

        <button *ngIf=" APP_ID == 383"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
      >
        <span class="navbar-toggler-icon" 
              [ngStyle]="{
                    'background-color': websiteSecColor
              }">
        </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav" *ngIf=" APP_ID != 359 && APP_ID != 383 && APP_ID != 363 && APP_ID != 376">

            <ng-container *ngFor="let item of Menu_items_sorted| slice:0:5; let i = index ">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.5rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                    'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of Menu_items_sorted| slice:5:6; let i = index ">
                <li
                  class="nav-item"
                  [ngStyle]="{ 'margin-inline': '1.5rem' }"
                  *ngIf="item.menu_design.header == 1"
                >
                  <a
                   
                    [ngStyle]="{
                       color: websiteSecColor,
                      'font-size' : Header_font_size ,
                      'white-space': 'nowrap',
                      'text-transform': Text_Style,
                      'font-family':text_type,
                      'font-weight': text_weight,
                      'background-color': Header_Color 
                    }"
                    class="nav-link button-link"
                    [routerLinkActive]="'active-link'"
                    (click)="navigatetoModule(item)"
                    >{{ item.name }}
                  </a>
                </li>
              </ng-container>




          </ul>
          <ul class="navbar-nav" *ngIf=" APP_ID == 359">

            <ng-container *ngFor="let item of Menu_items_sorted| slice:0:6; let i = index ">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.5rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                   'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of Menu_items_sorted| slice:6:7; let i = index ">
                <li
                  class="nav-item"
                  [ngStyle]="{ 'margin-inline': '1.5rem' }"
                  *ngIf="item.menu_design.header == 1"
                >
                  <a
                   
                    [ngStyle]="{
                       'color': websiteSecColor,
                      'font-size' : Header_font_size ,
                      'white-space': 'nowrap',
                      'text-transform': Text_Style,
                      'font-family':text_type,
                      'font-weight': text_weight,
                      'background-color': Header_Color 
                    }"
                    class="nav-link button-link"
                    [routerLinkActive]="'active-link'"
                    (click)="OpenPopup(item)"

                    >{{ item.name }}
                  </a>
                </li>
              </ng-container>




          </ul>

          <ul class="navbar-nav" *ngIf=" APP_ID == 376">

            <ng-container *ngFor="let item of Menu_items_sorted| slice:0:7; let i = index ">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.5rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                   'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of Menu_items_sorted| slice:7:8; let i = index ">
                <li
                  class="nav-item"
                  *ngIf="item.menu_design.header == 1"
                >
                  <a
                   
                    [ngStyle]="{
                       'color': websiteSecColor,
                      'font-size' : Header_font_size ,
                      'white-space': 'nowrap',
                      'text-transform': Text_Style,
                      'font-family':text_type,
                      'font-weight': text_weight,
                      'background-color': Header_Color 
                    }"
                    class="nav-link button-link"
                    [routerLinkActive]="'active-link'"
                    (click)="OpenPopup(item)"

                    >{{ item.name }}
                  </a>
                </li>
              </ng-container>




          </ul>

          <ul class="navbar-nav space-header" *ngIf=" APP_ID == 383 ">

            <ng-container *ngFor="let item of Menu_items_sorted| slice:0:4; let i = index ">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.5rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                   'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of Menu_items_sorted| slice:4:5; let i = index ">
                <li
                  class="nav-item"
                  [ngStyle]="{ 'margin-inline': '1.5rem' }"
                  *ngIf="item.menu_design.header == 1"
                >
                  <a
                   
                    [ngStyle]="{
                      'font-size' : Header_font_size ,
                      'white-space': 'nowrap',
                      'text-transform': Text_Style,
                      'font-family':text_type,
                      'font-weight': text_weight,
                      'background-color':  websiteSecColor
                    }"
                    style="color:white"
                    class="nav-link button-link"
                    [routerLinkActive]="'active-link'"
                    (click)="navigatetoModule(item)"
                    >
                    <i class="fa fa-phone" aria-hidden="true" style="margin :0px 10px"></i>
                    {{ item.name }}
                  </a>
                </li>
              </ng-container>




          </ul>

          <ul class="navbar-nav space-header" *ngIf="APP_ID == 363">

            <ng-container *ngFor="let item of Menu_items_sorted| slice:0:5; let i = index ">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.5rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                   'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of Menu_items_sorted| slice:5:6; let i = index ">
                <li
                  class="nav-item"
                  [ngStyle]="{ 'margin-inline': '1.5rem' }"
                  *ngIf="item.menu_design.header == 1"
                >
                  <a
                   
                    [ngStyle]="{
                      
                    color: Header_Bg_Color,
                    'font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'background-color': Header_Color
                    }"
                    style="padding: 7px 30px !important;"
                    class="nav-link button-link"
                    [routerLinkActive]="'active-link'"
                    (click)="navigatetoModule(item)"
                    >
                    {{ item.name }}
                  </a>
                </li>
              </ng-container>




          </ul>

        </div>


        <div
          class="collapse navbar-collapse lang-position"
          id="navbarNavDropdown"
        >

          <ul class="navbar-nav">

            <li
            class="search-toggle"
            *ngIf="lang.key == 'ar'"
            (click)="changeLang('en')"
          >
            <a class="d-flex" 
            [ngStyle]="{
              color: Header_Color,
             'font-size' : Header_font_size ,
             'text-transform': Text_Style ,
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> English </a>
            </li>
            <li
            class="search-toggle"
            *ngIf="lang.key == 'en'"
            (click)="changeLang('ar')"
          >
            <a class="d-flex" [ngStyle]="{
              color: Header_Color,
             'font-size' : Header_font_size ,
             'text-transform': Text_Style ,
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> عربى </a>
            </li>

          </ul>

        </div>


       
      </nav>
    </div>
  </header>
  

  