<header class="" [ngStyle]="{ 'background-color': Header_Bg_Color }" [ngClass]="menu?.Menu?.full_coverage==1?'header-full-width':''">
  <div class="full-width">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" (click)="navigateToHome()">
        <img class="logo" [src]="website_logo"   />
      
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let item of Menu_items_sorted; let i = index">
            <a
              (mouseover)="hoverHeaderItem = i"
              (mouseleave)="hoverHeaderItem = -1"
              *ngIf="
                item.menu_design.header == 1 
              "
              [ngStyle]="{
                 color: Header_Color,
                'font-size' : Header_font_size ,
                'text-transform': Text_Style ,
                'font-family':text_type,
                'font-weight': text_weight,
                'white-space': 'nowrap',
                'border-bottom':
                  hoverHeaderItem == i || itemSelectedFromHeader == item.name
                    ? '2px solid' + Header_Color
                    : 'none'
              }"
              class="nav-link"
              [routerLinkActive]="'active-link'"
              (click)="navigatetoModule(item)"
              >{{ item.name }}</a
            >
          </li>

         
        </ul>
      </div>

      <div
        class="collapse navbar-collapse lang-position"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav">
          <li class="searchbar " >
            <div style="position: relative;">
              <input
                class="search-new"
                type="text"
                placeholder="{{ 'searchWhatEver' | translate }}"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
              />
              <button
                [ngStyle]="{
                  right: lang.key == 'ar' ? '0px' : 'unset',
                  left: lang.key == 'en' ? '0px' : 'unset'
                }"
                class="search-btn-new"
                (click)="search()"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </li>

          <li
            class="search-toggle"
            *ngIf="lang.key == 'ar'"
            (click)="changeLang('en')"
          >
            <a class="d-flex" 
            [ngStyle]="{
              color: websiteSecColor,
             'font-size' : Header_font_size ,
             
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> En </a>
          </li>
          <li
            class="search-toggle b-none"
            *ngIf="lang.key == 'en'"
            (click)="changeLang('ar')"
          >
            <a class="d-flex" [ngStyle]="{
              color: websiteSecColor,
             'font-size' : Header_font_size ,
            
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> عربى </a>
          </li>

          <!-- <div class="dropdown">
              <button
                [ngStyle]="{
                  'background-color': websiteMainColor,
                  'border-color': websiteMainColor
                }"
                class="btn btn-secondary dropdown-toggle language-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "language" | translate }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="changeLang('ar')">
                  <img class="flag" src="assets/images/ar.png" />
                  عربى
                </a>
                <a class="dropdown-item" (click)="changeLang('en')">
                  <img class="flag" src="assets/images/en.jpg" />
                  English
                </a>
              </div>
            </div> -->

          <!-- <li class="nav-item" *ngIf="APP_ID != null ">
                          <a class="search-button whiteColor" style="border-radius: 0px;padding: 4px 25px;cursor: pointer;" [ngStyle]="{'background-color':websiteMainColor,'border-color': websiteMainColor}"  (click)="Logout()" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">
                               {{'Exist'|translate}}
                          </a>
                      </li> -->

          <!-- <select class="form-control">
                          <option> 
                              <img class="flag"  src="assets/images/ar.png">
                          </option>
                          <option>
                               <img class="flag"  src="assets/images/en.jpg">
                          </option>
  
                      </select> -->

          <!-- <mat-select>
                          <mat-option>
                            <img class="flag" src="assets/images/ar.png"> عربى
                          </mat-option>
                          <mat-option  >
                              <img class="flag" src="assets/images/en.jpg"> English
                            </mat-option>
                        </mat-select> -->

          <!-- <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='en_container'" (click)="changeLanguage('ar_container')">
                          <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                            <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                           عربى
                          </a>
                      </li> -->

          <!-- <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='ar_container'" (click)="changeLanguage('en_container')">
                          <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                            <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                           English
                          </a>
                      </li> -->
        </ul>
      </div>
    </nav>
  </div>
</header>

<app-auth-layout
  *ngIf="mylogin"
  (closeLoginPopup)="closeLogin($event)"
></app-auth-layout>
