import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-slider-image-sign-content',
  templateUrl: './slider-image-sign-content.component.html',
  styleUrls: ['./slider-image-sign-content.component.scss']
})
export class SliderImageSignContentComponent implements OnInit {
  @Input() component:any;
  slides: any[] = [];
  screenWidth=1024;
  responsiveOptions;
  scroll=2;
  visible=2;
  lang;
  responsiveOptionsCenter: { breakpoint: string; numVisible: number; numScroll: number; }[];

  constructor(private homeService:HomeService) {
    if (window.screen.width >= 1024) {
      this.screenWidth=window.screen.width;
      
    }
    this.responsiveOptions = [
      {
          breakpoint: this.screenWidth+'px',
          numVisible: 2,
          numScroll: 2

      },
      {
        breakpoint: '1440px',
        numVisible: 2,
        numScroll: 2

    },
      {
          breakpoint: '768px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '430px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  this.responsiveOptionsCenter = [
    {
        breakpoint: this.screenWidth+'px',
        numVisible: 4,
        numScroll: 2

    },
    {
      breakpoint: '1440px',
      numVisible: 4,
      numScroll: 2

  },
    {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '430px',
        numVisible: 1,
        numScroll: 1
    }
];
 
   }
   
  ngOnInit(): void {
  
    console.log(this.component);
    
    if (window.screen.width > 768 ) {
      this.scroll= this.visible =2;
    }
    else{
      this.scroll= this.visible =1;

    }
    if (window.screen.width > 768  && this.component.Style_content_position == 0) {
      this.scroll= this.visible =4;
    }
    else{
      this.scroll= this.visible =2;

    }

    this.lang = localStorage.getItem("container_lang");
     this.homeService.getHomeComponentsData( this.component).subscribe((res:any)=>{
       this.slides = res.content;
    
     },
     (error) => {
       // Handle error case
       console.error('API Request Failed:', error);
     },()=>
    {
      console.log("completed");
      
      this.toggleIndicators();
    })
    }
    toggleIndicators() {
      
      const indicators = document.querySelector('.p-carousel-indicators') as HTMLElement;
      if (indicators) {
        indicators.classList.toggle('hide-icon', this.component.slider_icon ==0);
      }
    }

}
