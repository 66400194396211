import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-title-desc-image3",
  templateUrl: "./title-desc-image3.component.html",
  styleUrls: ["./title-desc-image3.component.scss"],
})
export class TitleDescImage3Component implements OnInit {
  @Input() component: any;

  lang: string;
  currentLang: string;

  buttons: any;  
  sliderData: any[] = [];
  mainImage: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");

    this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
   
      this.sliderData = res.content != undefined && Array.isArray(res.content) ? res.content : res.content.data;
        console.log("comp --- ",this.component);

        if(this.sliderData != undefined)
        {
          if(this.sliderData.length > 1 && this.component?.bg_source != 'component_Bg')
            this.mainImage =  this.sliderData[1]?.image;
          else if (this.sliderData.length > 0 && this.component?.bg_source == 'component_Bg')
            this.mainImage =  this.sliderData[0]?.image;
        }

      this.buttons = this.component.component_buttons;
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.sliderData = res.content;
      });
    });
  }
}
