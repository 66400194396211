<header class="shadow" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="mobile">
      <a class="navbar-brand pull-right">
        <img class="logo" [src]="website_logo" />
      </a>
      <button
        class="navbar-toggler pull-left"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="row" style="width: 100%">
      <div class="col-md-2 col-sm-12 col-xs-12">
        <a class="navbar-brand desktop">
          <img class="logo" [src]="website_logo" />
        </a>
      </div>

      <div class="col-md-8 col-sm-12 col-xs-12">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
              <li
                class="nav-item"
                *ngIf="item.menu_design.header == 1"
                [ngStyle]="{ 'margin-inline': '0.5rem' }"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                    'font-size' : Header_font_size ,
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i ||
                      itemSelectedFromHeader == item.name
                        ? '2px solid' + websiteMainColor
                        : 'none'
                  }"
                  class="nav-link"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div class="col-md-2 col-sm-12 col-xs-12">
        <div
          class="collapse navbar-collapse lang-position"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav">
            <li class="nav-item dropdown desktop-only">
              <a
                [ngStyle]="{ 'background-color': Header_Bg_Color }"
                class="nav-link notification-button"
                href="#"
                id="notification"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-bell"></i>
                <span class="num">{{
                  notification_count | numberFormatter
                }}</span>
              </a>
              <div
                class="dropdown-menu notification-list"
                aria-labelledby="notification"
              >
                <li *ngFor="let notification of notificationList">
                  <a
                    class="nav-link dropdown-item  {{
                      notification.read_at ? 'notification-read' : ''
                    }}"
                    id="notifi"
                    (click)="readselected(notification)"
                  >
                    <span class="float-right">
                      {{ notification.notification_text }}
                    </span>

                    <small class="float-right time">
                      {{ notification.since }}
                    </small>
                  </a>
                </li>
              </div>
            </li>

            <div class="nav-item desktop-only" *ngIf="APP_ID != null">
              <span class="search-button">
                <div
                  [ngStyle]="{
                    'background-color': Header_Bg_Color,
                    'border-color': Header_Bg_Color,
                    'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight
                  }"
                  class="nav-link bold search-toggle bold search-btn"
                  aria-hidden="true"
                  (click)="clickEvent()"
                >
                  <i class="fa fa-search"></i>
                </div>
              </span>
              <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
                <div class="container">
                  <input
                    type="text"
                    (keyup.enter)="search()"
                    placeholder="{{ 'search' | translate }}"
                    [(ngModel)]="searchQuery"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <!-- <input type="button" value="بحث"/> -->
                  <button
                    [ngStyle]="{ 'background-color': Header_Bg_Color, 'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight }"
                    class="search-btn"
                    (click)="search()"
                  >
                    {{ "search" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <button
                [ngStyle]="{
                  'background-color': Header_Bg_Color,
                  'border-color': Header_Bg_Color,
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight
                }"
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "language" | translate }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  *ngIf="lang.key == 'en'"
                  (click)="changeLang('ar')"
                >
                  <img class="flag" src="assets/images/ar.png" />
                  عربى
                </a>
                <a
                  class="dropdown-item"
                  *ngIf="lang.key == 'ar'"
                  (click)="changeLang('en')"
                >
                  <img class="flag" src="assets/images/en.jpg" />
                  English
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
