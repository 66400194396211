
<div class=" top-mar">
    <div class="row">
        <div  class="col-md-12 col-sm-12 col-xs-12">
            <!-- <div class="row main-title-bg">
                <h5 class="main-title">
                  {{'Login AS'|translate}}
                </h5>
            </div> -->
            <div class="row">
                <form id="loginForm" class="form-style" [formGroup]="loginForm" >
                     
                  <div class="row">
                    <div class="" *ngFor="let app of applications" style="padding:0px 5px; width: 286px;" >
                        <div class="card" (click)="submitForm(app.id)" [id]="app.id">
                            <img src="{{app.logo}}">
                            <p>{{app.title}}</p>
                        </div>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                      <label>{{'AUTH.REGISTER.EMAIL'|translate}}</label>
                      <input type="text" class="form-control" formControlName="email">
                      <div *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid">
                        <div *ngIf="loginForm.get('email').errors.required" class=" text-right validator-error">
                          {{'VALIDATORS.REQUIRED'|translate}}
                        </div>
                      </div>
                    </div>
              
                    <div class="form-group">
                      <label>{{'Password'|translate}}</label>
                      <input type="password" class="form-control" formControlName="password">
                      <div *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid">
                        <div *ngIf="loginForm.get('password').errors.required" class=" text-right validator-error">
                          {{'VALIDATORS.REQUIRED'|translate}}
                        </div>
                      </div>
                    </div> -->
              
                    <!-- <div class="form-group"  > 
                      <label class="pull-left">
                        {{'Applications'|translate}}
                        
                      </label>
                      <select class="form-control" formControlName="application_id">
                        <option *ngFor="let app of applications" [value]="app.id">{{app.title}}</option>
                      </select>
                                  
                      
                     
                    </div> -->
                    <!-- <button type="submit">admin login</button> -->
                    <!-- <button [ngStyle]="{'background-color':websiteMainColor}" class="btn btn-xs  new pull-left"  style="font-size: 14px;" [disabled]="!loginForm.valid" type="submit">{{'Browse the site'|translate}}</button> -->
                    <!-- <a class="btn btn-xs btn-custom new pull-left" style="font-size: 14px;"  [routerLink]="['registration']" >{{'Register'|translate}}</a> -->
                    <!-- <a class="btn btn-xs btn-custom-dark new password-reset-btn pull-left" style="font-size: 14px;"   [routerLink]="['reset-password']" >{{'passwordreset'|translate}}</a> -->
              
                </form>

            </div>
        </div>

    </div>
</div>