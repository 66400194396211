import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviourSubjectService {
  
  private menuItemSource = new BehaviorSubject<string>(''); 
  menuItem$ = this.menuItemSource.asObservable(); 
  constructor() { }

  updateMenuItem(menuItemName){
    
    this.menuItemSource.next(menuItemName); // Push new value
  }
   
}
