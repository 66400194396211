import { Component, OnInit ,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-comments-with-cards',
  templateUrl: './comments-with-cards.component.html',
  styleUrls: ['./comments-with-cards.component.scss']
})
export class CommentsWithCardsComponent implements OnInit {

  @Input() component: any;

//   cards =[{to:'rania mokhtar' , message :'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident quia, voluptas placeat eum, accusamus illo minima dolores ex consequatur eligendi sunt architecto velit sequi soluta ipsum consequuntur facilis veritatis repellat?'
// , time:"22 hour ago"},{to:'rania mokhtar' , message :'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident quia, voluptas placeat eum, accusamus illo minima dolores ex consequatur eligendi sunt architecto velit sequi soluta ipsum consequuntur facilis veritatis repellat?'
// , time:"22 hour ago"},{to:'rania mokhtar' , message :'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident quia, voluptas placeat eum, accusamus illo minima dolores ex consequatur eligendi sunt architecto velit sequi soluta ipsum consequuntur facilis veritatis repellat?'
// , time:"22 hour ago"}]

imageTitleData;
ComponentTitle: any;
lang: string;
currentLang: string;
title_font_color;
title_font_size;
component_title_position;

hide_title;

i:number=0;

Data;
constructor(
  private homeService: HomeService,
  private translateService: TranslateService,

  ) {}

ngOnInit(): void {
  this.lang = localStorage.getItem("container_lang");
  this.currentLang = localStorage.getItem("lang");

  this.title_font_color = this.component.title_font_color;
  this.title_font_size = this.component.title_font_size;

  this.hide_title = this.component.show_cms_title

  this.component_title_position = this.component.component_title_position;

  this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
      this.imageTitleData = res.content;
      // this.ComponentTitle = res.meta_data.module_name;
      this.ComponentTitle = this.component.component_title;
       console.log("data",this.imageTitleData );
       
       

    });

  this.translateService.onLangChange.subscribe((res) => {
    this.currentLang = localStorage.getItem("lang");
     
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;
        this.ComponentTitle = this.component.component_title;

      });

  });
}
    next(){
      if(this.imageTitleData.length<this.i+4){

      }
      else{
        this.i=this.i+1;

      }
    }

    prev(){
      if(this.i<=0){

      }
      else{
        this.i=this.i-1;
      }
    }

}
