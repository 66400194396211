<header class="" [ngStyle]="{ 'background-color': Header_Bg_Color }" [ngClass]="menu?.Menu?.full_coverage==1?'header-full-width':''">
  <div class="full-width">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" (click)="navigateToHome()">
        <img class="logo" [src]="website_logo"   />
      
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        [ngStyle]="{ 'background-color': websiteMainColor, 'color':Header_Color}"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse w-100" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let item of Menu_items_sorted; let i = index">
            <a
              (mouseover)="hoverHeaderItem = i"
              (mouseleave)="hoverHeaderItem = -1"
              *ngIf="
                item.menu_design.header == 1 
              "
              [ngStyle]="{
                 color: Header_Color,
                'font-size' : Header_font_size ,
                'text-transform': Text_Style ,
                'font-family':text_type,
                'font-weight': text_weight,
                'white-space': 'nowrap'
                
              }"
              class="nav-link"
              [routerLinkActive]="'active-link'"
              (click)="navigatetoModule(item)"
              >{{ item.name }}</a
            >
          </li>

         
        </ul>
      </div>

      <div
        class="collapse navbar-collapse"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav d-flex align-items-center w-100">
          <li [ngStyle]="{ 'transform': lang.key == 'ar' ? 'scaleX(-1)' : 'none' }">
            <p > <i [ngStyle]="{
              color: websiteSecColor,
            
             
           
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }" class="fa fa-phone call"></i> 
            </p>
          </li>
          <li class="phone-data" [ngStyle]="{
            color: Header_Color,
           'font-size' : decreaseFontSize(Header_font_size),
           
           'font-family':text_type,
           'font-weight': text_weight,
           'white-space': 'nowrap'
           }">
            <p> 
              +252-335-9558 </p>
              <p > {{'Contact us' |translate}}</p>
           
          </li>

          <li
            class="search-toggle"
            *ngIf="lang.key == 'ar'"
            (click)="changeLang('en')"
          >
            <a class="d-flex" 
            [ngStyle]="{
              color: websiteSecColor,
             'font-size' : Header_font_size ,
             
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> En </a>
          </li>
          <li
            class="search-toggle b-none"
            *ngIf="lang.key == 'en'"
            (click)="changeLang('ar')"
          >
            <a class="d-flex" [ngStyle]="{
              color: websiteSecColor,
             'font-size' : Header_font_size ,
            
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> عربى </a>
          </li>

        </ul>
      </div>
    </nav>
  </div>
</header>

<app-auth-layout
  *ngIf="mylogin"
  (closeLoginPopup)="closeLogin($event)"
></app-auth-layout>