
<div   [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  style="padding: 0px 40px"
>
  <div
    *ngFor="let item of Three_Circles_Data | slice : 0 : article.items_number"
    class="col-md-4 col-sm-12 col-xs-12 "
    style="text-align: center; margin-bottom: 30px; cursor: pointer"
  >
    <div class="circle-container">
      <img
        *ngIf="item.type == 'image'"
        class="circle-img"
        src="{{ item.image }}"
        (click)="navigateToShow(item.id)"
      />
      <!-- <iframe
      *ngIf="item.type  != 'image' "
      [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
      width="90px"
      height="90px"
      frameborder="0"
      title="test"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>   -->
    </div>

   

    <h6
    
      class="names"
      style="text-align: center"
    >
      {{ item.title }}
    </h6>
    <small

      class="small-text"
      style="float: initial"
    >
      {{ item.description }}
    </small>
  </div>
</div>
</div>

