<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div 
    class="image-slider-sign mt-3"
    [class]="[component?.animations?.length > 1 ? component?.animations[1] : '']"
    [ngClass]="component?.slider_icon ==1?'show-icon':'hide-icon'"
    *ngIf="slides?.length > 0 && component?.Style_content_position != 0"
  >
    <p-carousel
    [circular]="false"
    [autoplayInterval]="10000"

      [value]="slides"
      [numVisible]="visible"
      [numScroll]="scroll" 
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="row" *ngIf="slide?.image">
          <div class="col-10 col-sm12 col-xs-12 side-slider">
            <div class="image-title">
              <img
                [src]="slide?.image"
                [ngClass]="
                  component.Style_image_shape == '0'
                    ? 'slider-img-circle'
                    : 'slider-img-square'
                "
              />
              <p
                class="py-3"
                [ngStyle]="{
                  'font-size': component?.title_size,
                  color: component?.title_color,
                  'font-weight': component?.data_title_weight
                }"
              >
                {{ slide?.title }}
              </p>
            </div>
            <div class="desc">
              <p
                [ngStyle]="{
                  'font-size': component?.description_size,
                  color: component?.description_color,
                  'font-weight': component?.data_desc_weight
                }"
              >
                {{ slide?.description }}
              </p>
            </div>
          </div>
        </div>
       
      </ng-template>
    </p-carousel>
  </div>
  <div 
  class="image-slider-sign mt-3"
 
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  *ngIf="slides?.length > 0 && component?.Style_content_position == 0"
>
  <p-carousel
  [circular]="false"
  [autoplayInterval]="10000"

    [value]="slides"
    [numVisible]="visible"
    [numScroll]="scroll"
    [responsiveOptions]="responsiveOptionsCenter"
  >
    <ng-template let-slide pTemplate="item">

      <div class="" *ngIf="slide?.image && component?.Style_content_position == 0">
        <div class="col-lg-10 col-sm-11 col-xs-12 center-slider"
         [ngStyle]="{'background-color':component?.Style_card_background, 'height':component.Style_image_shape == '0'?'250px':''}">
          <div class="image-title"  [ngStyle]="{'justify-content':component.Style_image_shape == '0'?'center':''}">
            <img
              [src]="slide?.image"
              [ngClass]="
                component.Style_image_shape == '0'
                  ? 'slider-img-circle'
                  : 'slider-img-square'
              "
            />
          
          </div>
          <div class="desc">
            <p
           
            [ngStyle]="{
              'font-size': component?.title_size,
              color: component?.title_color,
              'font-weight': component?.data_title_weight
            }"
          >
            {{ slide?.title }}
          </p>
            <p
              [ngStyle]="{
                'font-size': component?.description_size,
                color: component?.description_color,
                'font-weight': component?.data_desc_weight
              }"
            >
              {{ slide?.description }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
   
  </p-carousel>
</div>
  <div
    class="alert alert-warning text-center"
    role="alert"
    *ngIf="slides?.length == 0"
  >
  <p><b>#{{component?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
  </div>
</div>
